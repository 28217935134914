import * as THREE from "three";
import { CAMERA_PROPS, SCENE_PROPS } from "../../../constants/rendering";

export const camera = (width: number, height: number) => {
  const _ASPECT = width / height;

  const cam = new THREE.PerspectiveCamera(
    CAMERA_PROPS.fov,
    _ASPECT,
    CAMERA_PROPS.near,
    CAMERA_PROPS.far
  );

  return cam;
};

export const scene = (texture: THREE.Texture) => {
  const scene = new THREE.Scene();

  if (SCENE_PROPS.fog.enable) {
    scene.fog = new THREE.Fog(
      SCENE_PROPS.fog.color,
      SCENE_PROPS.fog.near,
      SCENE_PROPS.fog.far
    );
  }

  texture.mapping = THREE.EquirectangularReflectionMapping;
  texture.colorSpace = THREE.SRGBColorSpace;

  scene.environment = texture;

  return scene;
};

export const renderer = (options: any) => {
  const renderer = new THREE.WebGLRenderer(options || { alpha: false });
  return renderer;
};

export const HemiLight = () => {
  const hemiLight = new THREE.HemisphereLight(0xffffff, 0x437e49, 2.4);
  hemiLight.color.setHex(0xffffff);
  hemiLight.groundColor.setHex(0xffffff);
  return hemiLight;
};

export const SpotLight = () => {
  const spotLight = new THREE.SpotLight(0xffffff, 130);
  spotLight.position.set(10, 50, 20);

  const lightOffset = spotLight.position.length();

  spotLight.angle = Math.PI / 8;
  spotLight.penumbra = 1;
  spotLight.decay = 1;
  spotLight.distance = lightOffset * 1.71;

  spotLight.castShadow = false;
  spotLight.shadow.mapSize.width = 512;
  spotLight.shadow.mapSize.height = 512;
  spotLight.shadow.camera.near = 1.0;
  spotLight.shadow.camera.far = lightOffset;

  return spotLight;
};

export const TopPlane = (texture: any) => {
  texture.colorSpace = THREE.SRGBColorSpace;
  const planeGeometry = new THREE.PlaneGeometry(35, 46);
  const planeMaterial = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
  const plane = new THREE.Mesh(planeGeometry, planeMaterial);
  plane.position.set(-1,-5,-15);
  plane.rotation.set(-0.3,0,0)
  return plane;
};

export const BottomPlane = (texture: any) => {
  texture.colorSpace = THREE.SRGBColorSpace;
  const planeGeometry = new THREE.PlaneGeometry(36, 66);
  const planeMaterial = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
  const plane = new THREE.Mesh(planeGeometry, planeMaterial);
  //plane.position.set(-0.5,-100,-25);
  return plane;
};

