import { Link } from "react-router-dom";
import styled from "styled-components";
import Progressbar from "../../components/Progressbar";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

export const Fight = () => {
  return (
    <Wrapper>
      <div className="w-full h-full relative">
        <div className="flex justify-center items-center gap-2">
          <div className="w-full relative flex justify-start items-center gap-2 rounded-xl bg-[#D9D9D960] px-4 py-2">
            <div className="rounded-full w-7 h-7 bg-[#D9D9D9]"></div>
            <h6 className="text-sm leading-4 font-bold text-white">McSeemio Owl</h6>
          </div>
        </div>
        <div className="w-1/2 mx-auto">
          <div className="flex flex-col justify-end items-end mt-5">
            <h6 className="text-xl font-bold text-white opacity-40 text-end mb-1 mr-2">-1</h6>
            <div className="w-full mb-4">
              <Progressbar blurred={true} winking={false} spark={false} barcolor="bg-[#FF0000]" ht="min-h-2" percent="w-1/5" value={null} maxvalue={null} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/4">
        <h1 className="text-4xl font-bold text-[#FF0000] text-end mb-1 mr-2">-21</h1>
        <div className="w-full mb-4 mx-auto">
          <Progressbar blurred={true} winking={false} spark={false} barcolor="bg-[#FF0000]" ht="min-h-2" percent="w-1/4" value={null} maxvalue={null}/>
        </div>
      </div>
    </Wrapper>
  );
};

export default Fight;
