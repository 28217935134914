import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Game } from "./game";
import { AssetsManager } from "./AssetsManager";
import { UserStore, GameStore, ShopStore } from "../../store";
import { InventoryInfo, TraitsInfo } from "../../types/interface";

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

export const GameScene = (gameManager: any) => {
  const { gameRef, setGameRef, gameStage, trainMode } = GameStore((state) => ({
    gameRef: state.gameRef,
    setGameRef: state.setGameRef,
    gameStage: state.gameStage,
    trainMode: state.trainMode,
  }));

  const { userInfo } = UserStore((state) => ({
    userInfo: state.user,
  }));

  const { findShopItem } = ShopStore((state) => ({
    findShopItem: state.findItem,
  }));

  const defaultTrait: TraitsInfo = {
    body: "Brown",
    eyes: userInfo.eyes,
    chest: undefined,
  };

  const firstRef = useRef(false);
  const canvasDivRef = useRef(null);
  const assetsManagerRef = useRef(null) as any;

  const createGame = useCallback(async () => {
    const percentagecallback = (percent: number) => {
      gameManager.gameAssetManager(percent);
    };
    assetsManagerRef.current = new AssetsManager(
      gameStage,
      percentagecallback,
      findSkinName(),
      findStringLightName()
    );
    await assetsManagerRef.current.loadModels();

    startGame();
  }, [gameStage]);

  const startGame = () => {
    if (gameRef) return;
    const newGameRef = new Game({
      canvas: canvasDivRef.current!,
      assetsManager: assetsManagerRef.current,
      gameActionManagerCallback: gameManager.gameActionManager,
      gameStage: gameStage,
    });
    setGameRef(newGameRef);
  };

  useEffect(() => {
    if (gameStage === "" || firstRef.current) return;
    firstRef.current = true;
    createGame();
  }, [gameStage]);

  useEffect(() => {
    if (gameStage !== "stage1" && gameRef) {
      console.log("level up to", gameStage);
      gameRef.stageUp(gameStage);
    }
  }, [gameStage]);

  useEffect(() => {
    if (!gameRef) return;
    const skinName = findSkinName();
    const stringLights = findStringLightName();
    console.log("change color:  ", skinName);
    gameRef.modelUpdateChecker(skinName, stringLights);
  }, [userInfo.inventories]);

  useEffect(() => {
    if (gameRef) {
      gameRef.switchTrainMode(trainMode);
    }
  }, [trainMode]);

  const findSkinName = () => {
    const trait = userInfo.inventories
      ?.filter((inventory: InventoryInfo) => inventory.item.type === "skin")
      .find((inventory: InventoryInfo) => inventory.isEquipped)?.item.traits;
    if (trait) return trait;
    else return defaultTrait;
  };

  const findStringLightName = () => {
    return userInfo.inventories
      ?.filter(
        (inventory: InventoryInfo) => inventory.item.type === "decoration"
      )
      .find((inventory: InventoryInfo) => inventory.isEquipped)?.item.name;
  };

  return <Wrapper ref={canvasDivRef}></Wrapper>;
};

export default GameScene;
