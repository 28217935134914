import {BatchedRenderer, ParticleEmitter, QuarksLoader} from "three.quarks";
import {LoadingManager, Object3D, Texture, TextureLoader} from "three";

export const LoadParticleSystemPromise = (
    src: string,
    percentageCallback: Function,
    startPercent: number,
    endPercent: number): Promise<Object3D> => {
    return new Promise((resolve) => {
        const loadingManager = new LoadingManager();
        loadingManager.onProgress = (
            url: string,
            loaded: number,
            total: number
        ) => {
            percentageCallback(
                Math.ceil(startPercent + (loaded / total) * (endPercent - startPercent))
            );
        };
        const loader = new QuarksLoader(loadingManager);

        loader.load(src, function (object: Object3D) {
            resolve(object);
        });
    });
};

export const LoadTexturePromise = (
    src: string,
    percentageCallback: Function,
    startPercent: number,
    endPercent: number): Promise<Texture> => {
    return new Promise((resolve) => {
        const loadingManager = new LoadingManager();
        loadingManager.onProgress = (
            url: string,
            loaded: number,
            total: number
        ) => {
            percentageCallback(
                Math.ceil(startPercent + (loaded / total) * (endPercent - startPercent))
            );
        };
        const loader = new TextureLoader(loadingManager);

        loader.load(src, function (texture: Texture) {
            resolve(texture);
        });
    });
};
