import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components";
import IconLeft from "../../assets/icon-left.svg";
import Branch from "../../assets/icon-branch.svg";
import {useNavigateByExp} from "../../utils/levels";
import {UserStore} from "../../store";
import WebApp from "@twa-dev/sdk";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

export const Squad = () => {
  const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();
  const {userInfo, setUser} = UserStore((state) => ({userInfo: state.user, setUser: state.setUser}));

  WebApp.BackButton.onClick(()=>{
    WebApp.BackButton.hide();
    navigateByExp(userInfo.totalExp, null);
  });

  return (
    <Wrapper className="bg-gradient-to-t from-[#00000036] to-[#000] backdrop-blur">
      <div className="w-full">
        <div className="flex justify-between items-center gap-2 mb-1">
          {process.env.REACT_APP_ENV_DEV && (
              <div className="flex items-center gap-2 mb-1 w-full">
                <button className="w-4 h-full mt-2 pointer-events-auto" onClick={() => navigate(-1)}>
                  <img src={IconLeft} alt="IconLeft" />
                </button>
              </div>
          )}
          <div className="w-full relative flex justify-between rounded-xl bg-[#D9D9D960] px-4 py-2">
            <div className="rounded-full w-7 h-7 bg-[#D9D9D9]"></div>
            <div className="">
              <h6 className="text-sm leading-4 font-bold text-white">Durove’s Squad</h6>
              <p className="text-xs leading-4 font-normal text-white">21,012,002 Players</p>
            </div>
            <p className="text-xs font-normal text-[#A9A9A9]">TOP 3313</p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-6 mb-8">
          <div className="flex justify-center items-center gap-2 mb-4">
            <div className="flex justify-center items-center w-6 h-6 rounded-full bg-[#FFB800]">
              <div className="coin__front rounded-full w-3.5 h-3.5 bg-[#FF9900]"></div>
            </div>
            <h6 className="text-2xl font-bold text-white">1,212,223,333</h6>
          </div>
          <button className="text-sm text-white font-bold rounded-md bg-[#D9D9D950] px-8 py-1">invite frens</button>
        </div>
        <div className="max-h-64 overflow-y-auto scroll-mr-6">
          {
            [1, 2, 3, 4, 5, 6,].map((item, index) => (
              <div className="grid grid-cols-12 items-center gap-2 mb-2" key={index}>
                <div className="col-span-1 text-[10px] font-bold text-white">
                  <Link to={`/squad/${index+1}`} >#{index+1}</Link>
                </div>
                <div className="col-span-4">
                  <div className="flex items-center gap-2">
                    <div className="flex justify-center items-center w-3 h-3 rounded-full bg-[#D9D9D9]">
                    </div>
                    <h6 className="text-xs font-normal text-white opacity-60">162366 EXP</h6>
                  </div>
                </div>
                <div className="col-span-4">
                  <div className="flex items-center gap-1">
                    <div className="flex justify-center items-center w-3 h-3 rounded-full bg-[#FFB800]">
                      <div className="rounded-full w-1.5 h-1.5 bg-[#FF9900]"></div>
                    </div>
                    <span className="text-xs font-bold text-white">1,223,333</span>
                  </div>
                </div>
                <div className="col-span-3">
                  <button className="flex justify-center items-center gap-1 w-full text-[8px] text-white font-bold rounded-md bg-[#D9D9D950] py-1">
                    <img src={Branch} alt="branch"/>branch
                  </button>
                </div>
              </div>  
            ))
          }
        </div>
      </div>
    </Wrapper>
  );
};

export default Squad;
