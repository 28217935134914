import { Link } from "react-router-dom";
import { REACT_APP_BACKEND_URL } from "../../config";
import { GoodType } from "../../types/interface";
import TonIcon from "../../assets/icon-ton.svg";

type ItemProps = {
  itemType: string;
  item: GoodType;
}

const StoreItem = ({ item, itemType }: ItemProps) =>
  item ? (
    <div className="flex flex-col product justify-center items-center rounded-full pointer-events-auto">

      <Link to={`/store/${itemType}/${item.id}`} className="rounded-2xl bg-transparent w-full h-auto mb-2 pointer-events-auto">
        <img
          className="rounded-2xl w-full h-full"
          src={item.img}
          alt="item"
        />
      </Link>
      <div className="flex justify-center items-center">
        {itemType == "egg" ? (
          <img src={'https://notwise.b-cdn.net/icons/stars.svg'} width={18} height={18}/>
        ) : (
          <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18}/>
        )}

        <p className="text-sm leading-3 font-bold text-white ml-1.5">{item.price}</p>
      </div>
    </div>
  ) : (
    <p className="text-xs leading-3 font-bold text-white">empty</p>
  );

export default StoreItem;
