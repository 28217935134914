import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import IconLeft from "../../assets/icon-left.svg";
import IconRemove from "../../assets/icon-remove.svg";
import Branch from "../../assets/icon-branch.svg";
import { FRENS_ARRAY } from "../../constants";
import { useNavigateByExp } from "../../utils/levels";
import { UserStore } from "../../store";
import WebApp from "@twa-dev/sdk";
import { useState, useRef } from "react";

const MAX_FILE_SIZE = 500 * 1024; // 500KB

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

export const AddSquad = () => {
    const navigate = useNavigate();
    const navigateByExp = useNavigateByExp();
    const [userImg, setUserImg] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { userInfo, setUser } = UserStore((state) => ({ userInfo: state.user, setUser: state.setUser }));
    const fileInputRef = useRef<HTMLInputElement>(null);

    WebApp.BackButton.onClick(() => {
        WebApp.BackButton.hide();
        navigateByExp(userInfo.totalExp, null);
    });

    const loadFile = (e: any) => {
        if (typeof userImg === "string") {
            URL.revokeObjectURL(userImg);
        }
        const input = e.target;
        const file = input.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                setErrorMessage("File size exceeds 500KB");
                return;
            }
            setUserImg(URL.createObjectURL(file));
            setErrorMessage(null);
        }
    };

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Wrapper className="bg-gradient-to-t from-[#e8b20934] via-[#00000036] to-[#000]  backdrop-blur-xl z-2">
            <div className="w-full">
                {process.env.REACT_APP_ENV_DEV && (
                    <div className="flex items-center gap-2 mb-1 w-full">
                        <button className="w-4 h-full mt-2 pointer-events-auto" onClick={() => navigate(-1)}>
                            <img src={IconLeft} alt="IconLeft" />
                        </button>
                    </div>
                )}
                <div className="flex flex-col items-center justify-center w-full mb-1">
                    <h6 className="text-2xl font-bold text-white"><span className="text-[#ffe000] empires">Squad</span> Creation</h6>
                </div>
                <form action="#" method="POST" className="w-full h-full">
                    <div className="flex relative flex-col justify-center items-center mt-6 mb-2 gap-1">
                        <div onClick={handleImageClick} className="pointer-events-auto flex relative justify-center items-center w-24 h-24 bg-white/[5%] border border-white/[20%] rounded-full cursor-pointer">
                            {userImg === null ?
                                <img src="https://notwise.b-cdn.net/icons/icon-image.svg" alt="image" />
                                :
                                <img src={userImg} alt="image" className="w-full h-auto aspect-square rounded-full" />
                            }
                        </div>
                        <input type="file" ref={fileInputRef} onChange={loadFile} style={{ display: 'none' }} accept="image/png, image/jpeg"/>
                        <span className="text-white/[60%] text-xs text-center my-2">PNG / JPG square images only,<br/>{errorMessage === "File size exceeds 500KB" ? <b className="text-red-600">max file size is 500KB</b> : "max 500KB"}</span>

                    </div>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <label htmlFor="squad-name" className="block text-sm font-medium leading-6 text-white">Squad Name <span className="required text-white/[60%]">– required</span></label>
                            <div className="mt-2.5">
                                <input type="text" name="squad-name" id="squad-name"
                                       className="pointer-events-auto flex w-full rounded-xl bg-white/[5%] px-3.5 py-2 text-white shadow-sm border border-white/[30%] placeholder:text-gray-400 focus:ring-inset focus:border-white/[100%] outline-0" />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="squad-chat" className="block text-sm font-medium leading-6 text-white">Squad Chat @username</label>
                            <div className="mt-2.5">
                                <input type="text" name="squad-chat" id="squad-chat"
                                       className="pointer-events-auto flex w-full rounded-xl bg-white/[5%] px-3.5 py-2 text-white shadow-sm border border-white/[30%] placeholder:text-gray-400 focus:ring-inset focus:border-white/[100%] outline-0" />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="message"
                                   className="block text-sm font-medium leading-6 text-white">Squad Description <span className="required text-white/[60%]">– required</span></label>
                            <div className="mt-2.5">
                <textarea name="message" id="message" rows={2}
                          className="pointer-events-auto flex w-full rounded-xl bg-white/[5%] px-3.5 py-2 text-white shadow-sm border border-white/[30%] placeholder:text-gray-400 focus:ring-inset focus:border-white/[100%] outline-0"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <button type="submit" className="w-full text-base font-medium text-white bg-gradient-to-l from-[#FF6F41] via-[#FFAD42] to-[#FFE34F] rounded-md my-4 px-4 py-3">Create Squad</button>
                    </div>
                </form>
            </div>
        </Wrapper>
    );
};

export default AddSquad;
