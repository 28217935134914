import { gsap } from "gsap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tg_haptic } from "../../utils/telegramapi";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../../config";
import { InventoryInfo } from "../../types/interface";
import toast from "react-hot-toast";
import { UserStore } from "../../store";
interface InventoryProps {
  // need: any | null;
  // token: string;
  setIsShown: Function;
  setTrainMode: Function;
  setIsNeedShown: Function;
}

const difficulties = ["easy", "normal", "hard"];

const DifficultyWidget = (props: InventoryProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo, setUserInfo } = UserStore((state) => ({
    userInfo: state.user,
    setUserInfo: state.setUser,
  }));
  const { setIsShown, setTrainMode, setIsNeedShown } = props;
  const tg = window.Telegram.WebApp;
  const bigRef = useRef<HTMLDivElement>(null);
  const [inventories, setInventories] = useState<any[]>([]);
  const [isUnmounting, setIsUnmounting] = useState(false);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.fromTo(
      bigRef.current,
      { opacity: 0, scale: 0 },
      { opacity: 1, scale: 1, duration: 0.3 }
    );
  }, []);

  useEffect(() => {
    if (location.pathname === "/stage2/train") handleClose();
  }, [location.pathname]);

  const handleClick = async (difficulty: string) => {
    try {
      console.log(difficulty);

      setTrainMode(true);
      setIsNeedShown(false);
      tg_haptic.impactOccurred("light");
      navigate('/stage2/train');
    } catch (error) {
      console.error("Error Difficulty:", error);
      toast.error("Item use failed");
    }
  };
  const handleClose = () => {
    setIsUnmounting(true);
    const tl = gsap.timeline({
      onComplete: () => {
        setIsShown(false);
      },
    });

    tl.to(bigRef.current, {
      opacity: 0,
      scale: 0,
      duration: 0.3,
    });
  };

  return (
    <div
      ref={bigRef}
      className="w-full flex flex-col justify-between bg-gradient-to-t from-[#00000036] to-[#00000070] rounded-xl px-4 py-2 mb-4"
    >
      <div className="flex justify-between">
        <div className="text-white font-medium text-xs mb-2">Difficulty</div>
        <div className="pointer-events-auto" onClick={handleClose}>
          <img
            src={"https://notwise.b-cdn.net/icons/icon-close.svg"}
            alt="icon"
            width="20"
            height="auto"
          />
        </div>
      </div>
      <div className="w-full flex gap-2">
        {difficulties.map((difficulty: string, index: number) => (
          <div
            onClick={() => handleClick(difficulty)}
            key={index}
            className="flex relative flex-col w-1/3 justify-center items-center rounded-full"
          >
            <button className="relative rounded-2xl bg-transparent w-full h-auto mb-2.5 text-white pointer-events-auto">
              {difficulty}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
export default DifficultyWidget;
