import { Link } from "react-router-dom";
import styled from "styled-components";
import IconLeft from "../../assets/icon-left.svg";
import DoubleUp from "../../assets/icon-double-up.svg";
import DoubleDown from "../../assets/icon-double-down.svg";
import Up from "../../assets/icon-up.svg";
import Down from "../../assets/icon-down.svg";
import Branch from "../../assets/icon-branch.svg";
import Swords from "../../assets/icon-swords.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

export const Tree = () => {
  return (
    <Wrapper>
      <div className="w-full h-full relative">
        <div className="flex justify-between items-center gap-2 mb-1">
          <button className="w-4 h-full"><img src={IconLeft} alt="IconLeft" /></button>
          <div className="w-full relative flex justify-between rounded-xl bg-[#D9D9D960] px-4 py-2">
            <div className="rounded-full w-7 h-7 bg-[#D9D9D9]"></div>
            <div className="">
              <h6 className="text-sm leading-4 font-bold text-white">Durove’s Squad</h6>
              <p className="text-xs leading-4 font-normal text-white">21,012,002 Players</p>
            </div>
            <p className="text-xs font-normal text-[#A9A9A9]">TOP 3313</p>
          </div>
        </div>
        <div className="absolute right-0 bottom-1/2 translate-y-1/2 flex flex-col justify-center items-center gap-4">
          <button><img src={DoubleUp} alt="double-up" /></button>
          <button><img src={Up} alt="up" /></button>
          <p className="text-sm font-bold text-white">221</p>
          <button><img src={Down} alt="down" /></button>
          <button><img src={DoubleDown} alt="double-down" /></button>
        </div>
        <div className="absolute left-0 bottom-1/2 bg-[#D9D9D940] rounded-xl px-4 py-2">
          <p className="text-[10px] font-bold text-white mb-1"><span className="text-[#FFC700]">51%</span> chance</p>
          <button className="flex justify-center items-center gap-1 bg-[#D9D9D960] w-full text-[8px] text-white font-bold rounded-lg bg-[#D9D9D950] py-1 mb-1">
            <img src={Branch} alt="branch"/>branch
          </button>
          <button className="flex justify-center items-center gap-1 bg-[#FF4141] w-full text-[8px] text-white font-bold rounded-lg bg-[#D9D9D950] py-1">
            <img src={Swords} alt="branch"/>fight
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Tree;
