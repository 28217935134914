import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ShopStore, UserStore } from "../../store";
import { REACT_APP_BACKEND_URL } from "../../config";
import IconLeft from "../../assets/icon-left.svg";
import { GoodType, InventoryInfo,  } from "../../types/interface";
import axios from "axios";
import MainButton from "../../components/MainButton";
import {useNavigateByExp} from "../../utils/levels";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
  gap: 32px;
`;

export const InventoryItemDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const actionByType: { [key: string]: string } = {
     "food": "Use",
     "medicine": "Use",
     "egg": "Open",
     "decoration": "Use",
     "decoration_equipped": "Remove",
     "skin": "Equip",
     "skin_equipped": "Remove",
     "jetton": "Use"
  };
  const { userInfo, setUserInfo } = UserStore((state) => ({
    userInfo: state.user,
    setUserInfo: state.setUser,
  }));
  const navigateByExp = useNavigateByExp();
  const [item, setItem] = useState<InventoryInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const init = () => {
    const id = parseInt(params.id!);
    const found = userInfo.inventories?.find(
      (item: InventoryInfo) => item.id === id
    );
    if (found === undefined || found === null) return;

    setItem(found);
  };
  const btnText = item && actionByType[`${item.item.type}${item.isEquipped ? '_equipped' : ''}`];
  const handleUse = async (itemType: any, isEquipped: any) => {
    setIsLoading(true);
    try {
      if (item === null) return;
      if(itemType === "egg"){
        const res = await axios({
          method: "post",
          url: `${REACT_APP_BACKEND_URL}/inventory/useitem/${item.id}`,
          headers: {Authorization: `Bearer ${userInfo.token}`}
        });
        return;
      } else if(itemType === "food"){
        const { data }  = await axios({
          method: "post",
          url: `${REACT_APP_BACKEND_URL}/userAction/`,
          data: {'mode': 'owl', 'type': 'feed', 'item_id': item.id},
          headers: {Authorization: `Bearer ${userInfo.token}`}
        });
        setUserInfo({...userInfo, 'hunger': data.hunger, 'last_feeding': data.last_feeding});
        navigate('/profile');
        setIsLoading(false);
        return;
      }
      // revert to old item
      let oldItem: InventoryInfo | null = null;
      const old = userInfo.inventories?.find(
          (one: InventoryInfo) =>
              one.isEquipped &&
              one.id !== item.id &&
              one.item.type === item.item.type
      );
      // equip new item
      const { data } = await axios({
        method: "post",
        url: `${REACT_APP_BACKEND_URL}/inventory/${item.id}`,
        headers: { Authorization: `Bearer ${userInfo.token}` }
      });
      let newItem: InventoryInfo = {
        id: data.id,
        item: data.item,
        itemCount: data.item_count,
        isEquipped: data.is_equipped,
      };
      if (old) {
        oldItem = {
          id: old.id,
          item: old.item,
          itemCount: old.item_count,
          isEquipped: old.is_equipped,
        };
        setUserInfo({
          ...userInfo,
          inventories: [
            ...userInfo.inventories!.filter(
                (inventory: InventoryInfo) =>
                    inventory.id !== item.id && inventory.id !== old?.id
            ),
            newItem,
            oldItem,
          ],
        });
      } else {
        setUserInfo({
          ...userInfo,
          inventories: [
            ...userInfo.inventories!.filter(
                (inventory: InventoryInfo) => inventory.id !== item.id
            ),
            newItem,
          ],
        });
      }

      setItem(newItem);
      setIsLoading(false);
      navigateByExp(userInfo.totalExp, null)
    } catch (error) {
      setIsLoading(false);
      console.error("Error during using item:", error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Wrapper className="bg-gradient-to-t from-[#00000036] to-[#000] backdrop-blur">
      <div className="w-full">
        <div className="flex gap-2 mb-3">
          {process.env.REACT_APP_ENV_DEV && (
            <button
              className="w-4 h-full mt-2 pointer-events-auto"
              onClick={() => navigate(-1)}
            >
              <img src={IconLeft} alt="IconLeft" />
            </button>
          )}
        </div>
        <div className="flex flex-col justify-center items-center gap-6 px-2">
          <div className="w-full px-2 relative">
            {item ? (
                <>
                  <img
                    className="aspect-square w-full h-full rounded-[36px]"
                    src={item.item.img}
                    alt="item"
                  />
                  {item.isEquipped ?
                    <span
                        className="flex items-center text-sm font-medium absolute top-5 left-7"
                    >
                      <img src="https://notwise.b-cdn.net/icons/equi.svg" className={"w-5 min-w-5"} alt={"icon"}/>
                      <span
                          className="flex items-center font-medium absolute left-4 px-2 text-xs text-white/[60%]"
                      >
                        Equipped
                      </span>
                    </span>
                  :
                  <span
                      className="flex items-center text-sm font-medium absolute top-5 left-5"
                  >
                    <img src="https://notwise.b-cdn.net/icons/unequi.svg" className={"w-5 min-w-5"} alt={"icon"}/>
                  </span>
                  }
                </>
            ) : (
              <div className="aspect-square w-full h-full rounded-[36px]" />
            )}
          </div>
          <div className="w-full text-center">
            <h4 className="text-2xl font-bold text-white mb-1">
              {item ? item.item.name : "Loading..."}
            </h4>
            <div className="text-sm font-normal text-white">
              {item ? item.item.description : "Loading..."}
            </div>
            <div className="w-full mt-2">
              {item && item.item.traits && Object.entries(item.item.traits).map(([key, value]) => (
                <div className="flex flex-col items-center text-white/[60%] text-sm" key={key}>
                  <div className="flex">{key.charAt(0).toUpperCase() + key.slice(1)}:&nbsp;<span className="font-medium text-white">{value as string}</span></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <MainButton onClick={()=> handleUse(item?.item.type, item?.isEquipped)} isLoading={isLoading} btnText={btnText}/>
      </div>
    </Wrapper>
  );
};

export default InventoryItemDetails;
