import { create } from "zustand";
import { InventoryInfo, ShopInfo, GoodType } from "../types/interface";

const ShopStore = create<ShopInfo>((set, get) => ({
  foods: null,
  decorations: null,
  eggs: null,
  skins: null,
  medicines: null,
  setShopItems: (itemType: string, items: GoodType[]) => set((state) => ({ ...state, [itemType]: items })),
  findItem: (itemType: string, id: number) => {
    if (get()[itemType] === null) return null;
    return get()[itemType].find((item: GoodType) => item.id === id)
  },
  findInventories: (items: InventoryInfo[]) => {
    if (!items || items.length === 0) return null;

    let temp: GoodType[] = [];
    items.forEach((item: InventoryInfo) => {
      const id = item.itemId;
      const itemTypeArray = get()[item.itemType];

      // Check if itemTypeArray is undefined or null
      if (!itemTypeArray) return;

      const found = itemTypeArray.find((shopItem: GoodType) => shopItem.id === id);

      // Only push found items that are not undefined
      if (found) temp.push(found!);
    });

    return temp;
  }
}));

export default ShopStore;
