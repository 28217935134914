import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import IconLeft from "../../assets/icon-left.svg";
import Branch from "../../assets/icon-branch.svg";
import { FRENS_ARRAY } from "../../constants";
import {useNavigateByExp} from "../../utils/levels";
import {UserStore} from "../../store";
import WebApp from "@twa-dev/sdk";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

export const FrenInfo = () => {
  const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();
  const {userInfo, setUser} = UserStore((state) => ({userInfo: state.user, setUser: state.setUser}));

  WebApp.BackButton.onClick(()=>{
      WebApp.BackButton.hide();
      navigateByExp(userInfo.totalExp, null);
  });

  return (
    <Wrapper className="bg-gradient-to-t from-[#00000036] via-[#00000036] to-[#000] z-2">
        <div className="text-white/[60%]">Close X</div>
    </Wrapper>
  );
};

export default FrenInfo;
