import {createRef, RefObject, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap, Linear } from "gsap";
import { useNavigate } from "react-router-dom";

import IconLeft from "../../assets/icon-left.svg";
import IconDown from "../../assets/icon-down.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
  gap: 32px;
`;

export const ItemView = () => {
  const refGamesSection = useRef<HTMLDivElement | null>(null);
  const refGamesGrid = useRef<HTMLDivElement | null>(null);
  const [refGameGridItems, setRefGameGridItems] = useState<RefObject<HTMLDivElement>[]>([]);
  const [items, setItems] = useState<number[]>([]);
  const [wrapWidth, setWrapWidth] = useState(0);
  const [tween, setTween] = useState<gsap.core.Tween | null>(null);
  const [disableClick, setDisableClick] = useState(false);

  const navigate = useNavigate();
  const margin = 12;
  const boxWidth = 100 + margin;

  const wrap = useCallback((index: number) => {
      return gsap.utils.wrap(-boxWidth, wrapWidth, index);
    }, [wrapWidth]);

  const handleClick = (tween: gsap.core.Tween) => {
    tween.play();
    gsap.to(tween, { timeScale: 0.2, duration: 1 });
  };

  useEffect(() => {
    setItems(Array.from({ length: 6 }, (_, k) => k));
  }, []);

  useEffect(() => {
    setRefGameGridItems((prevState) =>
      Array.from(
        { length: items.length },
        (_, i) => prevState[i] || createRef<HTMLDivElement>()
      )
    );
    setWrapWidth((items.length - 1) * boxWidth);
  }, [items]);
  useEffect(() => {
    if (tween) {
      // setDisableClick(true);
      handleClick(tween);
      gsap.delayedCall(1, function () {
        gsap.to(tween, {
          timeScale: 0.2,
          duration: 1,
          onComplete: function () {
            this.pause();
            // setDisableClick(false);
          },
        });
      });
    }
  }, [tween])
  useEffect(() => {
    if (refGameGridItems.length === 0) {
      return;
    }

    gsap.set(refGamesGrid.current, {
      height: 100,
    });

    const items = refGameGridItems.map(
      (value) => value.current
    ) as HTMLDivElement[];

    gsap.set(items, {
      x: (i) => i * boxWidth,
      left: -boxWidth,
    });

    setTween(
      gsap
        .to(items, {
          duration: 1,
          x: "-=" + wrapWidth,
          ease: Linear.easeNone,
          repeat: -1,
          // paused: true,
          modifiers: {
            x(x) {
              return wrap(parseFloat(x)) + "px";
            },
          },
        })
        .progress(1 / items.length)
        .timeScale(0)
    );
  }, [refGamesSection, refGamesGrid, refGameGridItems, wrapWidth, wrap]);

  return (
    <Wrapper className="bg-gradient-to-t from-[#00000036] to-[#000] backdrop-blur">
      <div className="w-full">
        {process.env.REACT_APP_ENV_DEV && (
            <div className="flex items-center gap-2 mb-1 w-full">
              <button className="w-4 h-full mt-2 pointer-events-auto" onClick={() => navigate(-1)}>
                <img src={IconLeft} alt="IconLeft" />
              </button>
            </div>
        )}
      </div>
      <div className="relative w-full">
        <div className="flex justify-center mb-2">
          <img className="w-4 h-4" src={IconDown} alt="IconDown" />
        </div>
        <div className="relative overflow-hidden" ref={refGamesSection}>
          <div className="" ref={refGamesGrid}>
            {items.map(function (item, i) {
              return (
                <div
                  className="flex justify-center items-center bg-[#D9D9D9] opacity-100 rounded-3xl absolute w-[100px] aspect-square pointer-events-auto"
                  key={item}
                  ref={refGameGridItems[i]}
                >
                  {i + 1}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ItemView;
