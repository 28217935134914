import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { tg_haptic } from "../../utils/telegramapi";
import { useEffect, useRef } from "react";

interface NeedsProps {
    need: any | null;
    setIsNeedShown: Function;
    setIsInventoryShown: Function;
}

const NeedsWidget = (props: NeedsProps) => {
    const { need, setIsNeedShown, setIsInventoryShown } = props;
    const tg = window.Telegram.WebApp;
    const smallRef = useRef<HTMLDivElement>(null);
    const bigRef = useRef<HTMLDivElement>(null);
    const tlRef = useRef<gsap.core.Timeline | null>(null);

    useEffect(() => {
        const tl = gsap.timeline();
        tl.fromTo(
            smallRef.current,
            { opacity: 0, scale: 0 },
            { opacity: 1, scale: 1, duration: 0.3 }
        ).fromTo(
            bigRef.current,
            { opacity: 0, scale: 0 },
            { opacity: 1, scale: 1, duration: 0.3 }
        );

        // Save the timeline to the ref
        tlRef.current = tl;
    }, []);

    const handleInventoryShow = () => {
        const tl = gsap.timeline({onComplete:
                () => {
                    setIsNeedShown(false)
                    }
                }
                );
        tl.to(
            smallRef.current,
            { opacity: 0, scale: 0, duration: 0.5 }
        ).to(
            bigRef.current,
            { opacity: 0, scale: 0, duration: 0.3 },
            "-=0.3"
        );
        setIsInventoryShown(true);
    }

    return (
        <div className="relative">
            <div ref={smallRef} className="bg-white absolute p-2 bottom-0 rounded-full"></div>
            <div ref={bigRef} onClick={handleInventoryShow} className="bg-white pointer-events-auto absolute w-14 p-4 bottom-5 left-2 rounded-full">
                <img src="https://notwise.b-cdn.net/icons/criossant.png" className="w-14 h-auto" alt="icon"/>
            </div>
        </div>
    );
};

export default NeedsWidget;
