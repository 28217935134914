import { create } from "zustand";
import { Wallet } from "../types/interface";

const WalletStore = create<Wallet>((set, get) => ({
  wallet: {
    address: '',
    connected: false,
    hasNFT: false,
  },
  setWallet: (payload) => set((state) => ({ ...state, wallet: payload })),
}));

export default WalletStore;
