import styled from "styled-components";
import { Link } from "react-router-dom";
import Progressbar from "../../components/Progressbar";
import OverflowerText from "../../components/OverflowerText";
import Thermometer from "../../components/Thermometer";

import UserStore from "../../store/UserStore";
import GameStore from "../../store/GameStore";
import { Round } from "../../utils/math";
import { tg_haptic } from "../../utils/telegramapi";
import MainNav from "../../components/MainNav";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 28px 20px;
  width: 100%;
  height: 100%;
`;

const Stage1 = () => {
  const userInfo = UserStore((state) => state.user);
  const isTouch = GameStore((state) => state.isTouch);
  const tg = window.Telegram.WebApp;
  return (
    <Wrapper>
      <div className="flex flex-col justify-start items-center relative w-full h-full">
        {/*<SquadWidget squadInfo={userInfo.squad}/>*/}
        <Link
          to="/leaderbird"
          onClick={() => {tg_haptic.impactOccurred("light"); tg.BackButton.show()}}
          className="flex flex-col items-center pointer-events-auto"
        >
          <div className="absolute leaderbird w-max flex justify-between text-white">
            <img
              className="opacity-50"
              src="https://notwise.b-cdn.net/icons/wingl.svg"
              width={24}
              height={24}
              alt={"icon"}
            />
            <span className="mx-2 font-medium opacity-90">
              #{userInfo.rank}
            </span>
            <img
              className="opacity-50"
              src="https://notwise.b-cdn.net/icons/wingr.svg"
              width={24}
              height={24}
              alt={"icon"}
            />
          </div>
          <div className="absolute mt-7 flex justify-between">
            <div className="text-white font-normal">🥚&nbsp;Egg Stage <span className="font-semibold text-sm text-white/[60%] ml-1">LVL <span className="text-white">{userInfo.level}</span></span></div>
            <i className="icons8 text-white/[60%] -rotate-90 ml-1">&#xf116;</i>
          </div>
        </Link>
        <div className="w-full h-full left-0">
          <OverflowerText className="text-white absolute" />
        </div>
        <div className="absolute w-full h-auto top-[calc(50%-50px)] ">
          <div
            className={`absolute w-full h-full ${isTouch ? "heating" : ""}`}
          ></div>
          <div className="h-52 w-4 bg-[#D9D9D960] backdrop-blur rounded-full flex items-center justify-center">
            <Thermometer
              barcolor="bg-gradient-to-t from-[#FF5741FF] to-[#ff0000]"
              ht="h-48"
              percent="h"
              spark={false}
              value={userInfo?.temperature}
              maxvalue={20}
              customStyle={{
                height: `${ Math.min(100, Round( (userInfo.temperature / 20) * 100, 1))}%`,
              }}
            />
          </div>
          {/*<Slider
            containerClassName="relative w-4 h-[200px]" // absolute left-10 top-1/3 translate-y-1/2
            progressClassName={`w-5 h-[200px] absolute top-0 left-1/2 -translate-x-1/2 bg-gradient-to-t rounded-lg from-[#34C2FF] via-[#7DFF5C] to-[#FF6847]`}
            styles={{
              clipPath: `circle(10px at center ${calcTemperature(
                userInfo.heats
              )}px)`,
            }}
          />
         */}
        </div>
      </div>
      <div className="w-full flex items-center justify-between mb-20 rounded-xl px-3">
        <div className="w-4/12 flex items-center">
          <span className="text-2xl">⚡</span>
          <span className="text-white font-medium ml-1 text-sm leading-3">
            <span className="text-xl font-bold">{userInfo.energy}</span>
            <br />
            <span className="opacity-70">/ 1000</span>
          </span>
        </div>
        <Progressbar
          blurred={true}
          winking={false}
          barcolor="bg-gradient-to-r from-[#FF6F41] via-[#FFAD42] to-[#FFE34F]"
          ht="min-h-3"
          percent="w"
          spark={false}
          value={userInfo.energy}
          maxvalue={1000}
          customStyle={{ width: `${Round(userInfo.energy / 10, 1)}%` }}
        />
      </div>
      {/*<div className="w-full flex items-center justify-between mb-4  rounded-xl px-1">
        <div className="w-3/12 flex items-center">
          <img src={`${CDN_GATEWAY_BASE}/icons/lvl.svg`} width={16} height={16} alt="icon"/>
          <span className="text-white font-medium ml-1 text-sm">lvl 1</span>
        </div>
        <Progressbar
            barcolor="bg-gradient-to-r from-[#FF6F41] to-[#FEFBE9]"
            ht="min-h-3"
            percent="w"
            spark={true}
            value={userInfo.totalExp}
            maxvalue={100000}
            customStyle={{ width: `${Round(userInfo.totalExp/2000, 1)}%` }}
        />
      </div>
      */}
      <MainNav isBranch={true} isTrainMode={false} setIsNeedShown={false} setTrainMode={false} userExp={userInfo.totalExp} skillPoints={userInfo.skillPoints}/>
    </Wrapper>
  );
};

export default Stage1;
