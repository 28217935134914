let tg;
if (window.Telegram) {
  tg = window.Telegram.WebApp;
}

//let tg_init = process.env.REACT_APP_TEST == "true" ? "12345" : tg.initData; // ;
let tg_init = process.env.REACT_APP_TEST == "true" ? "151472920" : tg.initData; // ;
const tg_account = tg.initDataUnsafe.user;
const tg_haptic = tg.HapticFeedback;

export { tg_init, tg_account, tg_haptic };
