import { CDN_GATEWAY_BASE } from "../config";

export const MODEL_URLS = {
  // owl_big: `${CDN_GATEWAY_BASE}/models/Owl_anim_attributes_glb.glb`,
  owl: `${CDN_GATEWAY_BASE}/models/Owl_anim_attributes_texture_glb.glb`,
  dummy: `${CDN_GATEWAY_BASE}/models/dummy_x.glb`,
  tree: `${CDN_GATEWAY_BASE}/models/tree5.glb`,
  egg: `${CDN_GATEWAY_BASE}/models/egg2.glb`,
  notwise1_effect: `./assets/effects/notwise1.json`,
  notwise2_effect: `./assets/effects/notwise2.json`,
  textures: `${CDN_GATEWAY_BASE}/Textures`,
};

export const TEXTURE_URLS = {
  food1: `${CDN_GATEWAY_BASE}/images/quarks/food1.png`,
  food2: `${CDN_GATEWAY_BASE}/images/quarks/food2.png`,
  food3: `${CDN_GATEWAY_BASE}/images/quarks/food3.png`,
};

export const OWL_ANIMATION_NAMES = [
  "idle",
  "falls_asleep_v1",
  "wakes_up_v1",
  "falls_asleep_v2",
  "wakes_up_v2",
  "sleep",
  "agressive_v1",
  "attack_v1",
  "agressive_v2",
  "attack_v2",
  "satisfied_v1",
  "satisfied_v2",
  "look_with_interest_v1",
  "look_with_interest_v2",
  "owl_is_ill_v1",
  "owl_is_ill_v2",
];

export const OWL_ANIMATION_TIMELINE: any = {
  idle: {
    startFrame: 10,
    endFrame: 1000,
  },
  falls_asleep_v1: {
    startFrame: 1040,
    endFrame: 1275,
  },
  wakes_up_v1: {
    startFrame: 1285,
    endFrame: 1320,
  },

  falls_asleep_v2: {
    startFrame: 1330,
    endFrame: 1445,
  },

  wakes_up_v2: {
    startFrame: 1455,
    endFrame: 1635,
  },
  sleep: {
    startFrame: 1645,
    endFrame: 1925,
  },
  agressive_v1: {
    startFrame: 1935,
    endFrame: 2040,
  },
  attack_v1: {
    startFrame: 2050,
    endFrame: 2075,
  },
  agressive_v2: {
    startFrame: 2085,
    endFrame: 2315,
  },
  attack_v2: {
    startFrame: 2325,
    endFrame: 2370,
  },
  satisfied_v1: {
    startFrame: 2380,
    endFrame: 2470,
  },
  satisfied_v2: {
    startFrame: 2480,
    endFrame: 2565,
  },
  look_with_interest_v1: {
    startFrame: 2575,
    endFrame: 2925,
  },
  look_with_interest_v2: {
    startFrame: 2935,
    endFrame: 3285,
  },
  owl_is_ill_v1: {
    startFrame: 3295,
    endFrame: 3615,
  },
  owl_is_ill_v2: {
    startFrame: 3625,
    endFrame: 3945,
  },
};

export const EGG_ANIMATION_NAMES = [
  //"T_pose",
  "anim_Tap_01",
  "anim_Tap_02",
  //"anim_Tap_03",
  "anim_Tap_04",
];

export const EGG_ANIMATION_TIMELINE: any = {
  T_pose: {
    startFrame: 0,
    endFrame: 0,
  },
  anim_Tap_01: {
    startFrame: 10,
    endFrame: 30,
  },
  anim_Tap_02: {
    startFrame: 40,
    endFrame: 80,
  },
  anim_Tap_03: {
    startFrame: 90,
    endFrame: 120,
  },
  anim_Tap_04: {
    startFrame: 130,
    endFrame: 150,
  },
};

export const TREE_ANIMATION_NAMES = ["idle_tree"];

export const TREE_ANIMATION_TIMELINE: any = {
  idle_tree: {
    startFrame: 0,
    endFrame: 1082,
  },
};

export const DUMMY_ANIMATION_NAMES = [
  // "T_Pose",
  "damage_v1",
  "damage_v2",
  "damage_v3",
  "damage_v4",
  "damage_v5",
];

export const DUMMY_ANIMATION_TIMELINE: any = {
  T_pose: {
    startFrame: 0,
    endFrame: 0,
  },
  damage_v1: {
    startFrame: 10,
    endFrame: 50,
  },
  damage_v2: {
    startFrame: 60,
    endFrame: 115,
  },
  damage_v3: {
    startFrame: 125,
    endFrame: 165,
  },
  damage_v4: {
    startFrame: 175,
    endFrame: 230,
  },
  damage_v5: {
    startFrame: 240,
    endFrame: 290,
  },
};

export const SQUAD_ARRAY = [{ exp: 162366, rate: 50 }];

export const LEADERBIRD_ARRAY = [
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
  { username: "mcseemio", exp: 162366, coins: 50 },
];

export const FRENS_ARRAY = [
  { username: "mcseemio", earn: 330 },
  { username: "user1", earn: 130 },
  { username: "user2", earn: 30 },
];

export const SKILL_DESCR: any = {
  "Strength": "Attack power",
  "Agility": "Speed & evasion",
  "Wisdom": "Critical chance",
  "Endurance": "Max HP supply"
}
export const chestData: any = {
  "Bow Tie": "",
  "NOT Chainz": [
    "Chaiz_NOT_low",
    "Chaiz_1_low",
    "Chaiz_2_low",
    "Chaiz_Ring_1_low",
  ],
  "Bolo Tie": "",
  Tie: "",
  None: "",
};

const body = [
  "Artistry",
  "Blue",
  "Buckle",
  "Camo",
  "Cherry",
  "Pure Gold",
  "Gray",
  "Green",
  "Brown",
  "Nightcity",
  "Polar",
  "Python",
  "Rose",
  "Violet",
  "Demon",
];

const chest = ["Bow Tie", "NOT Chainz", "Bolo Tie", "Tie", "None"];

const cigarette = ["Cigarette", "Pipe", "None"];

const glasses = ["Aviator", "Pirate", "Las Vegas"];

const hat = [
  "Birthday Hat",
  "Broken Egg",
  "Captain Hat",
  "Cherry",
  "Crown",
  "Detective Hat",
  "Golden Egg",
  "Nest",
  "Nimbus",
  "Patch",
  "Unicorn",
  "Horns",
  "Fire Sign",
  "None",
];
