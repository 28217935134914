import { randomNumber } from "../../utils/math";
import { convertUV } from "../../utils/three";
import AnimationManager from "./AnimationManager";
import AssetsManager from "./AssetsManager";
import { GameOptions } from "./game";
import * as THREE from "three";
import userStore from "../../store/UserStore";
import { actionStore } from "../../store/ActionStore";
import { tg_haptic } from "../../utils/telegramapi";
import { DUMMY_ANIMATION_NAMES } from "../../constants";
import { UserStore } from "../../store";
import { ParticleSystemManager } from "./rendering/ParticleSystemManager";
import {Intersection} from "three";

export class UserActionManager {
  _canvasDiv: HTMLDivElement;
  _assetsManager: AssetsManager;
  _animationManager: AnimationManager;
  _camera: THREE.Camera;
  _raycaster: THREE.Raycaster;
  _scene: THREE.Scene;
  _light: THREE.Light;
  _psManager: ParticleSystemManager;
  _gameActionManager: any;
  _gameStage: string;
  _tapTime: number | null;
  _currentInterval: number;
  _minInterval: number;
  _decrement: number;
  _resetDelay: number;
  _resetTimeout: any;
  _lightChangeSpeed: number;
  _lightChangeProgress: number;
  _tapsAmount: number;
  _expArray: number[];
  _earnedExp: number[];
  _trainMode: boolean;
  //_randomFunc: Function;
  holdInterval: any;
  initialX: number | null = null;
  initialY: number | null = null;
  SWIPE_THRESHOLD: number = 30;

  _touchStartTime: any;
  LOOP_THRESHOLD: number = 1500;

  constructor(
    options: GameOptions,
    animationManager: AnimationManager,
    particleSystemManager: ParticleSystemManager,
    camera: THREE.Camera,
    scene: THREE.Scene,
    light: THREE.Light
  ) {
    this._canvasDiv = options.canvas;
    this._assetsManager = options.assetsManager;
    this._psManager = particleSystemManager;
    this._gameStage = options.gameStage;
    this._animationManager = animationManager;
    this._camera = camera;
    this._raycaster = new THREE.Raycaster();
    this._scene = scene;
    this._light = light;
    this._gameActionManager = options.gameActionManagerCallback;
    this._trainMode = false;

    this._tapTime = null;
    this._tapsAmount = 0;
    this._currentInterval = 1500;
    this._minInterval = 500;
    this._decrement = 0.9;
    this._resetDelay = 3000;
    this._resetTimeout = null;
    this._lightChangeProgress = 0;
    this._lightChangeSpeed = 0.01;
    this._expArray = [
      1, 1, 1, 1, 2, 2, 3, 4, 5, 6, 7, 6, 5, 4, 3, 2, 2, 1, 1, 1, 1,
    ];
    this._earnedExp = [];
    //this._randomFunc = this.seedRandom(userStore.getState().user.seed);
    this.holdInterval = null;
    this.initialX = null;
    this.initialY = null;

    this.initEventHandler();
    this.loop();
  }

  initEventHandler() {
    this._canvasDiv.addEventListener(
      "touchstart",
      this.touchStartHandler.bind(this)
    );
    this._canvasDiv.addEventListener(
      "touchend",
      this.touchEndHandler.bind(this)
    );

    document.addEventListener("startgame", this.gameStartHandler.bind(this));
  }

  getIntersections(x: number, y: number) {
    this._raycaster.setFromCamera(convertUV(x, y), this._camera);

    const intersects = this._raycaster.intersectObjects([
      this._trainMode
        ? this._assetsManager._models.dummy
        : this._assetsManager._models.character,
    ]);

    return intersects;
  }

  // RANDOMIZE EXP
  //seedRandom(seed: number) {
  //  let a = 1664525;
  //  let c = 1013904223;
  //  let m = Math.pow(2, 32);
  //  let state = seed;

  //  return function() {
  //    state = (a * state + c) % m;
  //    return state / m;
  //  };
  //}

  //getRandomNumber(randomFunc: Function, min: number, max: number) {
  //  return Math.floor(randomFunc() * (max - min + 1)) + min;
  //}

  // CHANGE LIGHT FUNC
  //setLight(prevColor: any, nextColor: any) {
  //  this._lightChangeProgress += this._lightChangeSpeed;
  //  if (this._lightChangeProgress > 1) {
  //    this._lightChangeProgress = 0;
  //  }
  //  this._light.color.lerpColors(
  //      prevColor,
  //      nextColor,
  //      this._lightChangeProgress
  //  );
  //  this._light.intensity = 150;
  //}

  touchStartHandler(ev: any) {
    ev.preventDefault();
    this.initialX = ev.changedTouches[0].clientX;
    this.initialY = ev.changedTouches[0].clientY;

    if (this._gameStage === "stage1") {
      if (
        this.getIntersections(
          ev.changedTouches[0].clientX,
          ev.changedTouches[0].clientY
        ).length > 0
      ) {
        this.processClick(ev);
        this.startHoldTimer();
        if (this._tapTime || this._tapsAmount !== 0) {
          this._animationManager.resetPlayAction("character", "anim_Tap_01");
        }
      }
    } else {
      const intersections =
          this.getIntersections(
              ev.changedTouches[0].clientX,
              ev.changedTouches[0].clientY
          );
      if (intersections.length > 0) {
        this._touchStartTime = Date.now();
        if (this._trainMode) {
          // this._animationManager.resetPlayAction(
          //   "dummy",
          //   DUMMY_ANIMATION_NAMES[Math.floor(Math.random() * 5)]
          // );
          console.log("ON DUMMY");
        } else {
          console.log("ON OWL");
        }
      }
    }
  }

  touchEndHandler(ev: any) {
    ev.preventDefault();
    if (this._gameStage === "stage1") {
      this.stopHoldTimer();
      this._gameActionManager("touchend");
    } else if (this._gameStage === "stage2") {
      if (!this._touchStartTime) return;

      if (this.initialX === null || this.initialY === null) return;
      const finalX = ev.changedTouches[0].clientX;
      const finalY = ev.changedTouches[0].clientY;

      const diffX = finalX - this.initialX;
      const diffY = finalY - this.initialY;

      if (
        Math.abs(diffX) > this.SWIPE_THRESHOLD ||
        Math.abs(diffY) > this.SWIPE_THRESHOLD
      ) {
        this.processSwipe(diffX, diffY);
      } else {
        const intersections = this.getIntersections(
            this.initialX,
            this.initialY
        );
        if (Date.now() - this._touchStartTime >= 1000) {
          console.log("tap&hold");
          this.processTapHold(intersections);
          userStore.getState().updateUser(1, 1, 'happiness');
          if(this._trainMode) {
            this._gameActionManager("touchend_dummy");
            return;
          }
          this._gameActionManager("touchend");
        } else {
          console.log("tap");
          this.processClick(ev, intersections); // Bugs without animations!
        }
      }

      this._touchStartTime = null;
    }
  }

  processClick(ev: any, intersections: Intersection[] = []) {
    const energy = userStore.getState().user.energy;
    this._tapsAmount++;
    if (this._gameStage === "stage2") {
      if (this._trainMode) {
        if (intersections.length > 0) {
          this._psManager.playAttackBang(intersections[0].point);
        }
        this._animationManager.resetPlayAction("dummy", "damage_v1");
        if (energy > 0) {
          userStore.getState().updateUser(0, 1, 'experience');
        }
      } else {
        this._psManager.playQuestionMarks();
        this._animationManager.playAction("character", "look_with_interest_v1");
        if (energy > 0) {
          userStore.getState().updateUser(0, 1, 'coins');
        }
      }
    } else {
      const expEarned = this._expArray[userStore.getState().user.temperature];
      //const expEarned = this.getRandomNumber(this._randomFunc, 1, maxExpByTemp);
      if (energy > 0) {
        userStore.getState().updateUser(expEarned, 1, 'experience');
      }
      const addExpData = {
        x: ev.changedTouches[0].clientX,
        y: ev.changedTouches[0].clientY,
        amount: energy < 1 ? 0 : expEarned,
      };
      this._psManager.playClickEffect();
      const addExp = new CustomEvent("addExp", { detail: addExpData });
      document.dispatchEvent(addExp);

    }
    this._tapTime = Date.now();
    this._gameActionManager("touchstart");
  }

  processSwipe(diffX: number, diffY: number) {
    if (this._trainMode) {
      this._psManager.playClawScratch(Math.atan2(-diffY, diffX));
      this._animationManager.resetPlayAction("dummy", "damage_v2");
      userStore.getState().updateUser(1, 1, 'experience');
      this._gameActionManager("swipe_dummy");
    } else {
      this._animationManager.playAction("character", "satisfied_v1");
      userStore.getState().updateUser(1, 1, 'happiness');
      this._gameActionManager("swipe_owl");
    }
    actionStore().updateActions("swipes", 1);
    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (diffX > 0) {
        console.log("Swiped right");
        this._psManager.playPet();
        //this._psManager.playHealEffect();
        // Handle swipe right
      } else {
        console.log("Swiped left");
        this._psManager.playPet();
        // Handle swipe left
      }
    } else {
      if (diffY > 0) {
        console.log("Swiped down");
        //this._psManager.playFeedEffect();
        this._psManager.playPet();
        // Handle swipe down
      } else {
        console.log("Swiped up");
        //this._psManager.playPet();
        this._psManager.playFeedEffect(2);
        // Handle swipe up
      }
    }
  }

  processTapHold(intersections: Intersection[]) {
    if (this._trainMode) {
      if (intersections.length > 0) {
        this._psManager.playAttackBoom(intersections[0].point);
      }
      this._animationManager.resetPlayAction("dummy", "damage_v4");
    } else {
      this._psManager.playPet();
      this._animationManager.playAction("character", "satisfied_v2");
    }
    actionStore().updateActions('holds', 1);
  }

  startHoldTimer() {
    if (!this.holdInterval) {
      if (this._gameStage === "stage1") {
        this.holdInterval = setInterval(() => {
          actionStore().updateActions('holds', 1);
          this._psManager.updateHeatEffect(UserStore.getState().user.temperature);
          this.dispatchHeatEvent();
        }, 1500);
      }
    }
  }

  stopHoldTimer() {
    if (this.holdInterval) {
      clearInterval(this.holdInterval);
      this.holdInterval = null;
      this._psManager.updateHeatEffect(-1);
    }
  }

  dispatchHeatEvent() {
    tg_haptic.impactOccurred("light");
    const addHeatData = { x: 50, y: 50 };
    const addHeat = new CustomEvent("addHeat", { detail: addHeatData });
    document.dispatchEvent(addHeat);
    userStore.getState().updateUser(1, 5, 'temperature');
  }

  gameStartHandler(ev: any) {
    const action = Object.values(
      this._animationManager._actions.character as Object
    )[this._gameStage === "stage1" ? 1 : 0];
    action.clampWhenFinished = true;
    action.reset().play();
  }
  loop() {
    requestAnimationFrame(this.loop.bind(this));
    if (!this._tapTime) return;

    const offset = Date.now() - this._tapTime;
    if (offset >= 500 && this._tapsAmount > 0) {
      this.dispatchClickEvent();
      this.resetClicks();
    }

    /*if (this._tapsAmount > 199) {
      this.dispatchClickEvent();
      this.resetClicks();
    }*/
  }

  dispatchClickEvent() {
    console.log(this._earnedExp);
    actionStore().updateActions("clicks", this._tapsAmount);
    if (this._trainMode) {
      this._gameActionManager("clickSessionEndDummy");
      return;
    }
    this._gameActionManager("clickSessionEnd");
  }

  resetClicks() {
    this._tapsAmount = 0;
    this._earnedExp = [];
    actionStore().updateActions('clicks', 0);
  }

  stageUp(upStage: string) {
    this._gameStage = upStage;
  }

  dispose() {
    this._canvasDiv.removeEventListener(
      "touchstart",
      this.touchStartHandler.bind(this)
    );

    this._canvasDiv.removeEventListener(
      "touchend",
      this.touchEndHandler.bind(this)
    );

    document.removeEventListener("startgame", this.gameStartHandler.bind(this));
  }
}

export default UserActionManager;
