import {Link, useNavigate} from "react-router-dom";
import {tg_haptic} from "../../utils/telegramapi";
import {useNavigateByExp} from "../../utils/levels";
import {LEVELS} from "../../config";
interface NavProps {
    isBranch: boolean;
    isTrainMode: any;
    setIsNeedShown: any;
    setTrainMode: any;
    userExp: number;
    skillPoints: number;
}

const MainNav = (props: NavProps) => {
    const navigate = useNavigate();
    const navigateByExp = useNavigateByExp();
    const { isTrainMode, setTrainMode, setIsNeedShown, isBranch, userExp, skillPoints  } = props;
    const handleClick = (type: string) => {
        tg_haptic.impactOccurred("light");
        switch (type) {
            case "home":
                navigateByExp(userExp, null);
                break;
            default:
                navigate(`/${type}`);
        }
    }
    return (
        <>
        { userExp < LEVELS[1] ?
                <div
                    className="fixed bottom-0 w-full max-w-80 mb-6 z-10 flex justify-between bg-[#D9D9D960] backdrop-blur rounded-xl px-4 py-2 left-1/2 transform -translate-x-1/2">
                    <span
                          className="flex flex-col items-center w-1/4 text-white/[60%]">
                        <i className="icons8 text-2xl">&#xf10f;</i>
                        <p className="text-xs font-normal">frens</p>
                    </span>
                    <span
                          className="flex flex-col items-center w-1/4 text-white/[60%]">
                        <i className="icons8 text-2xl">&#xf10f;</i>
                        <p className="text-xs font-normal">earn</p>
                    </span>
                    <span
                          className="flex flex-col items-center w-1/4 text-white/[60%]">
                        <i className="icons8 text-2xl">&#xf10f;</i>
                        <p className="text-xs font-normal">shop</p>
                    </span>
                    {isBranch ?
                    <span onClick={() => handleClick('profile')}
                          className="flex flex-col items-center pointer-events-auto w-1/4 text-white">
                        <i className="icons8 text-2xl">&#xf120;</i>
                        <p className="text-xs font-normal">my owl</p>
                        {skillPoints > 0 &&
                            <span className="absolute flex h-3 w-3 right-7">
                                <span
                                    className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        }
                    </span>
                        :
                        <span onClick={() => {
                            handleClick('home');
                        }}
                              className="flex flex-col items-center pointer-events-auto w-1/4 text-white">
                            <i className="icons8 text-2xl">&#xf115;</i>
                            <p className="text-xs font-normal">branch</p>
                        </span>
                    }
                </div>
                :
                <div
                    className="fixed bottom-0 w-full max-w-80 mb-6 z-10 flex justify-between bg-[#D9D9D960] backdrop-blur rounded-xl px-4 py-2 left-1/2 transform -translate-x-1/2">
                    <Link to="/frens" className="flex flex-col items-center pointer-events-auto w-1/5 text-white">
                        <i className="icons8 text-2xl">&#xf112;</i>
                        <p className="text-xs font-normal">frens</p>
                    </Link>
                    <Link to="/earn" onClick={() => handleClick('earn')}
                          className="flex flex-col items-center pointer-events-auto w-1/5 text-white">
                        <i className="icons8 text-2xl">&#xf113;</i>
                        <p className="text-xs font-medium">earn</p>
                    </Link>
                    {isBranch ?
                        isTrainMode ?
                            <Link to="/stage2" className="flex flex-col items-center pointer-events-auto w-1/5 text-white"
                                  onClick={() => {
                                      setTrainMode(false);
                                      tg_haptic.impactOccurred("light");
                                  }}>
                                <i className="icons8 text-2xl">&#xf115;</i>
                                <p className="text-xs font-normal">branch</p>
                            </Link>
                            :
                            <button className="flex flex-col items-center pointer-events-auto w-1/5 text-white" onClick={() => {
                                setTrainMode(true);
                            }}>
                                <i className="icons8 text-2xl">&#xf11c;</i>
                                <p className="text-xs font-normal">train</p>
                            </button>
                        :
                        <span className="flex flex-col items-center pointer-events-auto w-1/5 text-white" onClick={() => {
                            handleClick('home');
                        }}>
                    <i className="icons8 text-2xl">&#xf115;</i>
                    <p className="text-xs font-normal">branch</p>
                </span>
                    }
                    <span onClick={() => handleClick('store')}
                          className="flex flex-col items-center pointer-events-auto w-1/5 text-white">
                        <i className="icons8 text-2xl">&#xf110;</i>
                        <p className="text-xs font-normal">shop</p>
                    </span>
                    <span onClick={() => handleClick('profile')}
                          className="flex flex-col items-center pointer-events-auto w-1/5 text-white">
                        <i className="icons8 text-2xl">&#xf120;</i>
                        <p className="text-xs font-normal">my owl</p>
                        {skillPoints > 0 &&
                            <span className="absolute flex h-3 w-3 right-7">
                    <span
                        className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
                        }
                    </span>
                </div>
        }
        </>
    );
};
export default MainNav;
