import axios from "axios";

const client = axios.create({
  baseURL : 'https://tonapi.io',
  headers: {
  //  Authorization: `<Your Auth Token>`,
    "Content-Type": "application/json",
  }, 
});

export default client;
