import { useEffect, useRef } from 'react';
import gsap from 'gsap';

const useAnimation = (animationOptions: any) => {
    const elementRef = useRef(null);

    useEffect(() => {
        if (elementRef.current) {
            gsap.fromTo(elementRef.current, animationOptions.from, animationOptions.to);
        }
    }, [animationOptions]);

    return elementRef;
};

export default useAnimation;