import { Link } from "react-router-dom";
import {tg_haptic} from "../../utils/telegramapi";
interface SquadProps {
    squadInfo: any | null;
}

const SquadWidget = (props: SquadProps) => {
    const { squadInfo } = props;
    console.log(squadInfo);
    const tg = window.Telegram.WebApp;
    const handleJoin = () => {
        const newWindow = window.open("https://t.me/dev_not_gamebot/squads", '_blank');
        if (newWindow) {
            newWindow.focus();
        }
    }
    return (
        squadInfo ?
                <span onClick={() => {tg_haptic.impactOccurred("light"); handleJoin(); }} className="relative flex justify-start items-center rounded-xl bg-[#D9D9D930] backdrop-blur w-80 m-auto px-4 py-2 mb-5 pointer-events-auto">
                    <img src={`${squadInfo.logo}`} className="w-2/12 rounded-full"/>
                    <div className="ml-4 w-10/12">
                        <h6 className="text-sm font-bold text-white">{squadInfo.name}</h6>
                        <p className="text-sm font-normal text-white">{squadInfo.members} members</p>
                    </div>
                </span>
                :
                <div className="relative flex justify-center items-center w-80 m-auto mb-5">
                    <span onClick={() => {tg_haptic.impactOccurred("light"); handleJoin(); }} className="text-white flex flex-grow justify-center items-center rounded-xl bg-[#D9D9D930] backdrop-blur px-4 py-4 pointer-events-auto">
                        <img
                            src="https://notwise.b-cdn.net/icons/icon-ton.svg"
                            width={18}
                            height={18}
                            alt={"icon"}
                        />
                        <span className="font-medium ml-3">Join Squad</span>
                    </span>
                </div>

    );
};
export default SquadWidget;
