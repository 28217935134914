import {useCallback} from "react";
import styled from "styled-components";
import GameScene from "../../components/GameScene";
import { tg_haptic } from "../../utils/telegramapi";
import { GameStore, UserStore, ActionStore } from "../../store";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../../config";

const startGameEvent = new Event("startgame");
const Wrapper = styled.div`
  position: absolute;
  background: transparent;
  // z-index: -1;
`;

const Editor = () => {
  const { userInfo, setUserInfo } = UserStore((state) => ({
    userInfo: state.user,
    setUserInfo: state.setUser,
  }));
  const { gameStage, trainMode,  isTouch, setIsTouch, load, setLoad } = GameStore((state) => ({
    trainMode: state.trainMode,
    gameStage: state.gameStage,
    isTouch: state.isTouch,
    setIsTouch: state.setIsTouch,
    load: state.load,
    setLoad: state.setLoad,
  }));
  // const [load, setLoad] = useState<number>(0);
  // let temp = useRef(load);

  const gameActionManager = async (userAction: string) => {
    switch (userAction) {
      case "startgame":
        document.dispatchEvent(startGameEvent);
        break;
      case "touchstart":
        tg_haptic.impactOccurred("light");
        touchObj(true);
        break;
      case "clickSessionEnd":
        if (
          ActionStore.getState().actions.holds > 0 ||
          ActionStore.getState().actions.clicks > 0
        ) {
          switch (gameStage) {
            case "stage1":
              await affectedItem("click", "egg");
              break;
            case "stage2":
              await affectedItem("click", "owl");
              break;
          }
        }
        break;
      case "clickSessionEndDummy":
        if (
          ActionStore.getState().actions.holds > 0 ||
          ActionStore.getState().actions.clicks > 0
        ) {
          switch (gameStage) {
            case "stage2":
              await affectedItem("click", "dummy");
              break;
          }
        }
        break;
      case "swipe_owl":
        switch (gameStage) {
          case "stage1":
            break;
          case "stage2":
            await affectedItem("swipe", "owl");
            break;
        }
        break;
      case "swipe_dummy":
        await affectedItem("swipe", "dummy");
        break;
      case "touchend_dummy":
        touchObj(false);
        if (
            ActionStore.getState().actions.holds > 0 ||
            ActionStore.getState().actions.clicks > 0
        ) {
          await affectedItem("hold", "dummy");
        }
        break;
      case "touchend":
        console.log('TouchEnd')
        touchObj(false);
        if (
            ActionStore.getState().actions.holds > 0 ||
            ActionStore.getState().actions.clicks > 0
        ) {
          switch (gameStage) {
            case "stage1":
              await affectedItem("hold", "egg");
              break;
            case "stage2":
              await affectedItem("hold", "owl");
              break;
          }
        }
        console.log("touchObj finished");
        break;
      default:
        break;
    }
  };

  const gameAssetManager = useCallback(
    (percent: number) => {
      setLoad(percent);
    },
    [load]
  );

  const affectedItem = useCallback(
    async (type: string, mode: string) => {
      const { clicks, holds, swipes, item_id } = ActionStore.getState().actions;
      try {
        const res = await axios({
          method: "post",
          url: `${REACT_APP_BACKEND_URL}/userAction/`,
          data: { mode: mode, type: type, holds: holds, clicks: clicks, swipes: swipes, item_id: item_id},
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        switch (type) {
          case "click":
            UserStore.getState().updateByUserInteraction(
              res.data.last_energy_used,
              0,
              0,
              "click"
            );
            break;
          case "hold":
            UserStore.getState().updateByUserInteraction(
              res.data.last_energy_used,
              res.data.last_heating,
              res.data.temperature,
              "hold"
            );
            break;
          case "swipe":
            UserStore.getState().updateByUserInteraction(
              res.data.last_energy_used,
              res.data.last_heating,
              res.data.temperature,
              "swipe"
            );
            break;
          case "feed":
            UserStore.getState().updateByUserInteraction(
              res.data.last_energy_used,
              res.data.last_heating,
              res.data.temperature,
              "feed"
            );
            break;
          case "heal":
            UserStore.getState().updateByUserInteraction(
              res.data.last_energy_used,
              res.data.last_heating,
              res.data.temperature,
              "heal"
            );
            break;
        }
        ActionStore.setState({
          actions: {
            mode: 0,
            clicks: 0,
            holds: 0,
            swipes: 0,
            heals: 0,
            feeds: 0
          }
        });
        console.log(res.data);
        // navigateByExp(res.data.player.experience);
      } catch (error) {
        console.log("heat_egg error", error);
      }
    },
    [isTouch, userInfo]
  );

  const touchObj = useCallback(
    (flag: boolean) => {
      setIsTouch(flag);
    },
    [isTouch]
  );
  return (
    <Wrapper className="w-full h-full">
      <GameScene
        gameActionManager={gameActionManager}
        gameAssetManager={gameAssetManager}
      />
    </Wrapper>
  );
};

export default Editor;
