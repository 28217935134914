import {useCallback, useEffect, useMemo, useRef} from "react";
import userStore from "../store/UserStore";

const UPDATE_INTERVAL = 480000

export const useTemperatureUpdate = () => {
  const last_energy_used = userStore(state => state.user.last_energy_used)
  const decreaseTemperature = userStore(state => state.decreaseTemperature)

  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const lastHeated = useMemo(() => new Date(last_energy_used), []);
  const secondsPassedHeat = useMemo(() => (new Date().getTime() - lastHeated.getTime()) / 1000, [lastHeated]);

  const decreaseTemperatureWithInterval = useCallback(() => {
    const tempToDecrease = 1;
    decreaseTemperature(tempToDecrease)

  }, [decreaseTemperature])

  useEffect(() => {
    const tempToDecrease = Math.floor(secondsPassedHeat / UPDATE_INTERVAL / 1000);

    if (tempToDecrease > 0) {
      decreaseTemperature(tempToDecrease)
    }
  }, [])

  useEffect(() => {
    const delay = secondsPassedHeat > UPDATE_INTERVAL ? 0 : UPDATE_INTERVAL - secondsPassedHeat

    const startInterval = () => {
      intervalRef.current = setInterval(decreaseTemperatureWithInterval, UPDATE_INTERVAL);
    }

    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(startInterval, delay);
    } else {
      startInterval()
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    };
  }, [secondsPassedHeat, decreaseTemperatureWithInterval]);
}
