import create from "zustand";
import { ActionInfo } from "../types/interface";
const useActionStore = create<ActionInfo>((set, get) => ({
  //modes: 0 - egg, 1 - owl, 2 - dummy
  actions: {
    mode: 0,
    clicks: 0,
    holds: 0,
    swipes: 0,
    feeds: 0,
    heals: 0,
  },
  setActions: (payload) => set((state) => ({ ...state, actions: payload })),
  updateActions: (actionName, increment) => set((state) => ({
    actions: {
      ...state.actions,
      [actionName]: state.actions[actionName] + increment
    }
  }))
}));

// Export store directly for non-hook usage
export const actionStore = useActionStore.getState;

export default useActionStore;