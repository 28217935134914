import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import axios from "axios";

import client from "../../utils/tonapi";
import {tg_account, tg_init} from "../../utils/telegramapi";
import { REACT_APP_BACKEND_URL } from "../../config";
import { UserStore, GameStore, WalletStore, ShopStore } from "../../store";
import WebApp from "@twa-dev/sdk";
import {useNavigateByExp} from "../../utils/levels";
import { GoodType, InventoryInfo } from "../../types/interface";

export const TonConnectProvider = ({children}: {children: React.ReactNode}) => {
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();

  const { userInfo, setUserInfo, setForeigns } = UserStore((state) => ({userInfo: state.user, setUserInfo: state.setUser, setForeigns: state.setForeigns}));
  const setGameStage = GameStore((state) => {return state.setGameStage});
  const setWallet = WalletStore((state) => {return state.setWallet});
  const { setShopItems } = ShopStore((state) => ({ setShopItems: state.setShopItems }));

  const hasNFT = async (addressId: string) => {
    try {
      const res = await client({
        url: `https://tonapi.io/v2/accounts/${addressId}/nfts?collection=${
          process.env.REACT_APP_TEST == "true"
            ? process.env.REACT_APP_TEST_NFT_COLLECTION1
            : process.env.REACT_APP_NFT_COLLECTION1
        }`,
        method: "GET",
      });
      if(res.data.nft_items.length == 0) {
        try {
          const res = await client({
            url: `https://tonapi.io/v2/accounts/${addressId}/nfts?collection=${
                process.env.REACT_APP_TEST == "true"
                    ? process.env.REACT_APP_TEST_NFT_COLLECTION1
                    : process.env.REACT_APP_NFT_COLLECTION2
            }`,
            method: "GET",
          });
          return res.data.nft_items.length > 0;
        }
        catch (error) {
          console.log("hasNFT() is failed", error);
          return false;
        }
      } else {
        return true;
      }
    } catch (error) {
      console.log("hasNFT() is failed", error);
      return false;
    }
  };

  const getShopItems = async () => {
    try {
      const res = await axios({ method: "get", url: `${REACT_APP_BACKEND_URL}/goods/` });
      const data: GoodType[] = res.data;

      const categories = ["food", "decoration", "egg", "skin", "medicine"];

      categories.forEach(category => {
        const items = data.filter(item => item.type === category);
        setShopItems(category + "s", items.length > 0 ? items : []);
      });
    } catch (error) {
      console.error("Error fetching goods:", error);
    }
  }

  const init = async (address: string) => {
    try {
      const isNFT = process.env.REACT_APP_ENV_DEV === "true" ? true : await hasNFT(address);

      if (!isNFT) {
        setWallet({address: address, connected: tonConnectUI.connected, hasNFT: false});
        navigate("/");
        return;
      }
      setWallet({address: address, connected: tonConnectUI.connected, hasNFT: true});
      const res1 = await axios({
        method: "post",
        url: `${REACT_APP_BACKEND_URL}/token`,
        params:
          process.env.REACT_APP_TEST == "true"
            ? { userId: tg_init }
            : { initData: tg_init },
      });



      // get player
      const res = await axios.get(`${REACT_APP_BACKEND_URL}/player/`, {headers:{Authorization: `Bearer ${res1.data.access_token}`}});

      // get inventory
      let temp: InventoryInfo[] = [];
      const {data} = await axios.get(`${REACT_APP_BACKEND_URL}/inventory/`,{headers: {"Authorization": `Bearer ${res1.data.access_token}`}});

      data.forEach((item: any) => {
        temp.push({
          id: item.id,
          item: item.item,
          itemCount: item.item_count,
          isEquipped: item.is_equipped,
        });
      })

      setUserInfo({
        totalExp: res.data.experience,
        temperature: res.data.temperature,
        energy: res.data.energy,
        level: res.data.level,
        skillPoints: res.data.skill_points,
        coins: res.data.coins,
        strength: res.data.strength,
        agility: res.data.agility,
        wisdom: res.data.wisdom,
        endurance: res.data.endurance,
        hunger: res.data.hunger,
        tg_id: process.env.REACT_APP_TEST == "true" ? tg_init: tg_account?.id,
        username: res.data.username ? res.data.username : null,
        isPremium: res.data.is_premium,
        lang: res.data.lang,
        inventories: temp,
        token: res1.data.access_token,
        rank: res.data.rank,
        health: res.data.health,
        happiness: res.data.happiness,
        lastHeating: res.data.last_heating,
        lastFeeding: res.data.last_feeding,
        lastEnergyUsed: res.data.last_energy_used,
        squad: res.data.squad,
      });
      navigateByExp(res.data.experience, setGameStage);
    } catch (error) {
      console.log("init() is failed", error);
    }
  };

  useEffect(() => {
    if (tonConnectUI.connected) {
      if (!process.env.REACT_APP_ENV_DEV) {
        init(userFriendlyAddress);
      }
    } else if (process.env.REACT_APP_ENV_DEV){
      setWallet({address: "DevTesting", connected: true, hasNFT: true});
      init('DevTesting');
    } else {
      setWallet({address: "", connected: false, hasNFT: false});
      navigate("/");
    }
    getShopItems();
  }, [tonConnectUI.connected]);
  
  return <>{children}</>;
};
