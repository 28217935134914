import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { tg_haptic } from "../../utils/telegramapi";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../../config";
import {InventoryInfo} from "../../types/interface";
import toast from "react-hot-toast";
import {UserStore} from "../../store";
interface InventoryProps {
    need: any | null;
    token: string;
    setIsShown: Function;
}

const InventoryWidget = (props: InventoryProps) => {
    const { userInfo, setUserInfo } = UserStore((state) => ({ userInfo: state.user, setUserInfo: state.setUser }));
    const { need, token, setIsShown } = props;
    const tg = window.Telegram.WebApp;
    const bigRef = useRef<HTMLDivElement>(null);
    const [inventories, setInventories] = useState<any[]>([]);
    const [isUnmounting, setIsUnmounting] = useState(false);

    useEffect(() => {
        const tl = gsap.timeline();

        tl.fromTo(
            bigRef.current,
            { opacity: 0, scale: 0 },
            { opacity: 1, scale: 1, duration: 0.3 }
        )
    }, []);

    useEffect(() => {
        const fetchInventory = async () => {
            try {
                const { data } = await axios.get(`${REACT_APP_BACKEND_URL}/inventory/${need}/`, {
                    headers: { "Authorization": `Bearer ${token}` }
                });
                console.log("Fetched inventoryWidget data:", data); // Debug logging
                setInventories(data); // Assuming data is an array of ShopItemInfo
            } catch (error) {
                console.error("Failed to fetch inventory", error);
            }
        };

        fetchInventory();
    }, [token, need]);

    const handleClick = async (item: any) => {
        try {
            const res = await axios({
                method: "post",
                url: `${REACT_APP_BACKEND_URL}/inventory/useitem/${item?.id}`,
                headers: {Authorization: `Bearer ${token}`}
            });

            // get inventory
            let temp: InventoryInfo[] = [];
            const {data} = await axios({
                method: "get",
                url: `${REACT_APP_BACKEND_URL}/inventory/`,
                headers: {"Authorization": `Bearer ${token}`}
            });

            data.forEach((item: any) => {
                temp.push({
                    id: item.id,
                    item: item.item,
                    itemCount: item.item_count,
                    isEquipped: item.is_equipped,
                });
            })
            setUserInfo({...userInfo, inventories: temp });
            toast.success("Item used successfully");
        } catch (error) {
            console.error("Error buying item:", error);
            toast.error("Item use failed");
        }
        const event = new CustomEvent('feed', {detail: item});
        document.dispatchEvent(event);
    }
    const handleClose = () => {
        setIsUnmounting(true);
        const tl = gsap.timeline({
            onComplete: () => {
                setIsShown(false);
            }
        });

        tl.to(bigRef.current, {
            opacity: 0,
            scale: 0,
            duration: 0.3,
        });
    }

    return (
        <div ref={bigRef} className="w-full flex flex-col justify-between bg-gradient-to-t from-[#00000036] to-[#00000070] rounded-xl px-4 py-2 mb-4">
            <div className="flex justify-between">
                <div className="text-white font-medium text-xs mb-2">{inventories.length > 0 && 'Quick Usage'}</div>
                <div className="pointer-events-auto" onClick={handleClose}>
                    <img src={'https://notwise.b-cdn.net/icons/icon-close.svg'} alt="icon" width="20" height="auto" />
                </div>
            </div>
            <div className="w-full flex gap-2">
                {inventories.length > 0 ?
                    inventories.map((item: any, index: number) => (
                        <div onClick={() => handleClick(item)} key={index} className="flex relative flex-col w-2/12 justify-center items-center rounded-full">
                            <button className="relative rounded-2xl bg-transparent w-full h-auto mb-2.5 pointer-events-auto">
                                <img src={item.item.img} className="rounded-2xl w-full h-full" alt="item" />
                            </button>
                            {item.item_count > 1 &&
                                <span className="absolute z-2 left-1 top-1 bg-gray-800/[.6] rounded-lg font-bold text-xs text-white px-2">x{item.item_count}</span>
                            }
                        </div>
                    )) : <span className="text-white/[50%] pb-3">
                         <Link onClick={() => {tg_haptic.impactOccurred("light"); tg.BackButton.show();}} to={'/store/'} className="pointer-events-auto w-full text-base font-medium text-white bg-gradient-to-l from-[#FF6F41] via-[#FFAD42] to-[#FFE34F] rounded-md my-4 px-4 py-3">Shop now</Link>
                    </span>
                }
            </div>
        </div>
    );
};
export default InventoryWidget;
