import WebApp from "@twa-dev/sdk";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import axios from "axios";
import {useNavigateByExp} from "../../utils/levels";
import {Round} from "../../utils/math";
import {tg_account, tg_haptic, tg_init} from "../../utils/telegramapi";
import { LEVELS, REACT_APP_BACKEND_URL} from "../../config";
import { ShopStore, UserStore } from "../../store";
import Edit from "../../assets/icon-edit.svg";
import Add from "../../assets/icon-add.svg";
import Health from "../../assets/icon-health.svg";
import Happy from "../../assets/icon-happy.svg";
import Food from "../../assets/icon-food.svg";
import IconConnect from "../../assets/icon-connect.svg";
import IconDisconnect from "../../assets/icon-disconnect.svg";
import IconCheck from "../../assets/icon-check.svg";
import Progressbar from "../../components/Progressbar";
import Inventory from "../../components/Inventory";
import {SKILL_DESCR} from "../../constants";
import MainNav from "../../components/MainNav";

type ProfileTabs = "attributes" | "inventory" | "achievements";

const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TabButton = styled.button<{ active: boolean }>`
  padding: 12px 16px;
  background: ${(props) => (props.active ? "rgba(255,255,255,0.06)" : "transparent")};
  color: #fff;
  border-bottom: none;
  cursor: pointer;
  border-radius: 12px 12px 0 0;
  text-align: center;
  &:focus {
    outline: none;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

const SKILLS = [
  {title: "Strength", skill: "strength"},
  {title: "Agility", skill: "agility"},
  {title: "Wisdom", skill: "wisdom"},
  {title: "Endurance", skill: "endurance"},
];

export const Profile = () => {
  const navigate = useNavigate();
  const [tonConnectUI] = useTonConnectUI();
  const navigateByExp = useNavigateByExp();
  const {userInfo, setUser, foreigns} = UserStore((state) => ({userInfo: state.user, setUser: state.setUser, foreigns: state.foreigns}));
  const { findInventories } = ShopStore((state) => ({ findInventories: state.findInventories }));
  const [activeTab, setActiveTab] = useState<ProfileTabs>("inventory");

  const [username, setUsername] = useState<string|undefined>(userInfo.username ? userInfo.username : tg_account?.username);
  const [isEditable, setEditable] = useState(false);
  const userFriendlyAddress = useTonAddress();

  const progress = ((LEVELS[userInfo.level] - userInfo.totalExp)/(LEVELS[(userInfo.level + 1)] - LEVELS[userInfo.level])) * -100;
  const handleWallet = async () => {
    // if (!tonConnectUI.connected) {
    //   await tonConnectUI.openModal();
    // } else {
      await tonConnectUI.disconnect();
      navigate('/');
    // }
  };
  WebApp.BackButton.onClick(()=>{
    WebApp.BackButton.hide();
    navigateByExp(userInfo.totalExp, null);
  });

  const changeUsername = async () => {
    try {
      setEditable(false);
      if (!username) return;
      await axios({
        method: "patch",
        url: `${REACT_APP_BACKEND_URL}/change-username/`,
        headers: {"Authorization": `Bearer ${userInfo.token}`},
        params: { new_username: username },
      });
      setUser({...userInfo, username: username ? username : null});
    } catch (error) {
      setUsername(userInfo.username!);
      console.log("change username error", error);
    }
  }

  const upSkill = async (skill: string) => {
    setUser({...userInfo, skillPoints: userInfo.skillPoints - 1, [skill]: userInfo[skill] + 1});
    try {
      await axios({method: "post", url: `${REACT_APP_BACKEND_URL}/up-skills/`, headers: {"Authorization": `Bearer ${userInfo.token}`}, params: {skill: skill}});
    } catch (error) {
      console.log("add strength skill error", error);
    }
  }


  return (
   <div className="block w-full h-full">
    <Wrapper className="bg-gradient-to-t from-[#e8b20934] via-[#00000036] to-[#000] backdrop-blur-xl overflow-y-auto scrollable no-scrollbar">
      <div className="w-full">
        <div className="flex w-full gap-2 h-14 mb-1 items-start">
          <div className="w-full flex justify-between items-center">
            <div>
              <div className="flex items-center gap-2">
                {isEditable ?
                    <div className="flex flex-col relative w-9/12">
                <input className="w-full rounded text-2xl font-bold text-[#2b2f37] px-1 pointer-events-auto" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                <span className="text-white/[50%] text-sm top-10 absolute">max. 13 symbols</span>
                </div>
                    :
                <h6 className={`${username && username.length > 7 ? "text-sm": "text-xl"} font-bold text-white`}>{username}</h6>}
                {isEditable ? (
                  <button className="pointer-events-auto" onClick={changeUsername}>
                    <img src={IconCheck} alt="Check"/>
                  </button>
                  ) : (
                  <button className="pointer-events-auto" onClick={() => setEditable(true)}>
                    <img src={Edit} alt="Edit"/>
                  </button>
                )}
              </div>
              {!isEditable &&
              <p className="text-sm font-normal text-[#607B93]">Durove’s Squad</p>
              }
            </div>
            {!isEditable &&
            <div className="flex items-center">
              <span className="text-white bg-white/[.1] rounded-3xl p-1 px-6">{userFriendlyAddress.slice(0,3) + "***" + userFriendlyAddress.slice(-3)}</span>
              {tonConnectUI.connected ?
                  <button className="pointer-events-auto ml-2" onClick={handleWallet}>
                    <img className="min-w-6 h-auto" src={IconDisconnect} alt="IconDisconnect" />
                  </button>:
                  <img className="min-w-6" src={IconConnect} alt="IconConnect" />}
            </div>
            }
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-6 gap-6">
          <div className="w-full flex flex-col justify-start items-start">
              <div className="flex w-full">
                <div className="text-lg font-bold text-white mb-1">
                  <span className="text-[#607B93]">LVL&nbsp;[&nbsp;</span>
                  {userInfo.level}
                  <span className="text-[#607B93]">&nbsp;]&nbsp;</span>
                </div>
                <div className="text-lg flex justify-center items-center ml-3">
                  <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18}/>
                  <p className="font-bold text-white ml-2">{userInfo.coins}</p>
                </div>
              </div>
            <div className="flex w-full items-center mt-1">
              <div className="text-sm font-normal text-white w-8/12">{userInfo.totalExp} / <span className="text-[#607B93]">{LEVELS[userInfo.level + 1]} EXP</span></div>
              <Progressbar
                  blurred={true}
                  winking={false}
                  barcolor="bg-gradient-to-r from-[#FF6F41] to-[#FEFBE9]"
                  ht="min-h-3"
                  percent="w"
                  spark={Round(progress, 1) > 20}
                  value={userInfo.totalExp}
                  maxvalue={LEVELS[userInfo.level + 1]}
                  customStyle={{ width: `${Round(progress, 1)}%` }}
              />
            </div>
          </div>
        </div>
        <div className={`w-12/12 flex items-center justify-between my-4 m-auto py-4 border-t border-white/[.1] ${userInfo.totalExp < LEVELS[1] ? "hidden" : "block"}`}>
          <div className="w-3/12 flex items-center">
            <img src={Food} width={20} height={20} alt="icon"/>
            <div className="flex flex-col  ml-3">
              <div className="text-white/[60%] text-xs">hunger</div>
              <div className="text-white">{userInfo.hunger} <span className="text-white/[60%]">/ 20</span></div>
            </div>
          </div>
          <div className="w-3/12 flex items-center">
            <img src={Happy} width={20} height={20} alt="icon"/>
            <div className="flex flex-col ml-3">
              <div className="text-white/[60%] text-xs">happiness</div>
              <div className="text-white">{userInfo.happiness} <span className="text-white/[60%]">/ 20</span></div>
            </div>
          </div>
          <div className="w-3/12 flex items-center">
            <img src={Health} width={20} height={20} alt="icon"/>
            <div className="flex flex-col  ml-3">
              <div className="text-white/[60%] text-xs">health</div>
              <div className="text-white">{userInfo.health} <span className="text-white/[60%]">/ 20</span></div>
            </div>
          </div>
        </div>
        <Tabs className="mt-4">
          <TabButton className={`pointer-events-auto flex flex-grow-0 font-medium text-sm`} active={activeTab === "inventory"} onClick={() => setActiveTab("inventory")}>
            Inventory
          </TabButton>
          <TabButton className={`relative pointer-events-auto flex justify-center items-center flex-grow font-medium text-sm `} active={activeTab === "attributes"} onClick={() => setActiveTab("attributes")}>
            <div>Skills</div>
            {userInfo.skillPoints > 0 &&
                <span className="absolute flex h-3 w-3 right-4">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
            }
          </TabButton>
          <TabButton className={`pointer-events-auto flex flex-grow-0 font-medium text-sm`} active={activeTab === "achievements"} onClick={() => setActiveTab("achievements")}>
            Achievements
          </TabButton>
        </Tabs>
        {activeTab === "attributes" && (
        <div className="w-full flex text-center gap-3 bg-white/[.06] rounded-xl p-4">
          {SKILLS.map((skill, index) =>
              <div className="flex flex-col gap-1" key={index}>
                <div className="flex items-center justify-center text-lg font-bold text-white mb-0 pb-0">
                  <>
                    {userInfo.skillPoints > 0 ?
                        <button
                            className="pointer-events-auto"
                            onClick={() =>{ tg_haptic.impactOccurred('light'); upSkill(skill.skill)}}>
                          <img className="w-7 h-7" src={Add} alt="Add"/>
                        </button>
                        :
                        <img className="w-4 h-4 rounded-full" src={`https://notwise.b-cdn.net/icons/${skill.title.toLowerCase()}.png`} alt="icon"/>
                    }
                  </>
                  <span className="min-w-6">{userInfo[skill.skill]}</span>
                </div>
                <div className="items-center text-sm font-bold text-white">
                  {skill.title}
                </div>
                <div className="items-center text-xs font-normal text-white/[60%]">
                  {SKILL_DESCR[skill.title]}
                </div>
              </div>
          )}
        </div>
        )}
        {activeTab === "inventory" && (
          <div className={`w-full bg-white/[.06] rounded-xl rounded-tl-none p-4 pointer-events-auto mb-18`}>
            {userInfo.inventories && userInfo.inventories?.length > 0 ?
            <Inventory token={userInfo.token}/>
              :
              <span className="text-white">No items yet</span>
            }
          </div>
        )}
        {activeTab === "achievements" && (
          <div className={`w-full bg-white/[.06] rounded-xl rounded-tr-none p-4 text-white pointer-events-auto mb-18`}>
            No achievements yet
          </div>
        )}

      </div>
    </Wrapper>
        <MainNav skillPoints={userInfo.skillPoints} userExp={userInfo.totalExp} isBranch={false}  isTrainMode={false} setIsNeedShown={false} setTrainMode={false}/>
    </div>
  );
};

export default Profile;
