// import * as THREE from "three";
import {
  OWL_ANIMATION_NAMES,
  OWL_ANIMATION_TIMELINE,
  MODEL_URLS,
  EGG_ANIMATION_NAMES,
  EGG_ANIMATION_TIMELINE,
  TREE_ANIMATION_NAMES,
  TREE_ANIMATION_TIMELINE,
  DUMMY_ANIMATION_NAMES,
  DUMMY_ANIMATION_TIMELINE,
  TEXTURE_URLS,
  chestData,
} from "../../constants";
import { GLTFLoaderPromise } from "./loaders/GLTFLoaderPromise";
import * as THREE from "three";
import {
  LoadParticleSystemPromise,
  LoadTexturePromise,
} from "./loaders/QuarksLoaderPromise";
import { Object3D } from "three";
import { TraitsInfo } from "../../types/interface";
import { TextureLoaderPromise } from "./loaders/TexureLoaderPromise";
import { CDN_GATEWAY_BASE } from "../../config";

export class AssetsManager {
  _models: any;
  _materials: any;
  _animations: any;
  _textures: any;
  _effects: { [key: string]: Object3D };
  _gameStage: string;
  _animationClips: any;
  _trait: TraitsInfo;
  _attributes: any;
  _decorations: any;
  _stringLights: string | undefined;
  _attributesArray: any;

  _percentageCallback: (percentage: number) => void;

  constructor(
    gameStage: string,
    percentageCallback: (percentage: number) => void,
    trait: TraitsInfo,
    stringLight: string | undefined
  ) {
    this._models = {};
    this._animations = {};
    this._animationClips = {
      tree: {},
      character: {},
      dummy: {},
    };
    this._textures = {};
    this._effects = {};
    this._attributes = {};
    this._decorations = {};
    this._attributesArray = [];

    this._gameStage = gameStage;
    this._percentageCallback = percentageCallback;
    this._trait = trait;
    this._stringLights = stringLight;

    if (process.env.REACT_APP_DEBUG_WEBGL) {
      this._trait = {
        body: 'Brown',
        chest: undefined,
        eyes: 'Brown'
      };
      this._stringLights = 'Color String Lights';
    }
  }

  loadModels() {
    return new Promise(async (resolve, reject) => {
      const treeObject = (await GLTFLoaderPromise(
        MODEL_URLS["tree"],
        this._percentageCallback,
        0,
        30
      ));
      treeObject.meshes.forEach((mesh: THREE.Mesh) => {
        if (this._gameStage === "stage1") {
          if (mesh.name === "Nest_low") mesh.visible = true;
          if (mesh.name === "Roof_low") mesh.visible = false;
          if (mesh.name === "Pipes_Low") mesh.visible = false;
          if (mesh.name === "Railing_Low") mesh.visible = false;
          if (mesh.name === "Garaland_low") mesh.visible = false;
          if (mesh.name === "Lights_blue_low") mesh.visible = false;
          if (mesh.name === "Lights_yellow_low") mesh.visible = false;
          if (mesh.name === "Lights_red_low") mesh.visible = false;
          if (mesh.name === "Lights_green_low") mesh.visible = false;
          console.log(mesh);
        } else {
          if (mesh.name === "Nest_low") mesh.visible = false;
          if (mesh.name === "Roof_low") mesh.visible = false;
          if (mesh.name === "Pipes_Low") mesh.visible = false;
          if (mesh.name === "Railing_Low") mesh.visible = false;
          if (mesh.name === "Garaland_low") mesh.visible = false;
          if (mesh.name.startsWith("Lights") || mesh.name === "Garaland_low") this._decorations[mesh.name] = mesh;
        }
      });
      this._models.tree = treeObject.scene;
      this._animations.tree = treeObject.animations[0];

      switch (this._gameStage) {
        case "stage1":
          const eggObject = (await GLTFLoaderPromise(
            MODEL_URLS["egg"],
            this._percentageCallback,
            31,
            80
          ));
          this._models.character = eggObject.scene.children[0];
          this._animations.character = eggObject.animations[0];
          const ps = await LoadParticleSystemPromise(
            MODEL_URLS["notwise1_effect"],
            this._percentageCallback,
            81,
            100
          );
          this._effects["Spark"] = ps.getObjectByName("Spark")!;
          this._effects["EggHeat"] = ps.getObjectByName("EggHeat")!;
          this._effects["EggCracking"] = ps.getObjectByName("EggCracking")!;
          break;
        case "stage2": {
          const owlObject = (await GLTFLoaderPromise(
            MODEL_URLS["owl"],
            this._percentageCallback,
            31,
            50
          ));

          this._models.character = owlObject.scene;
          this._animations.character = owlObject.animations[0];
          this._materials = owlObject.materials;

          owlObject.meshes.forEach((mesh: THREE.Mesh) => {
            if (mesh.name.startsWith("Owl")) return;
            this._attributesArray.push(mesh);
            mesh.visible = false;
            this._attributes[mesh.name] = mesh;
          });
          this._textures.temple = await TextureLoaderPromise(
              `${CDN_GATEWAY_BASE}/images/bg-bot.webp`,
              this._percentageCallback,
              51,
              54
          );
          const dummyObject = (await GLTFLoaderPromise(
            MODEL_URLS["dummy"],
            this._percentageCallback,
            55,
            70
          ));

          this._models.dummy = dummyObject.scene.children[0];
          this._animations.dummy = dummyObject.animations[0];

          const ps = await LoadParticleSystemPromise(
            MODEL_URLS["notwise2_effect"],
            this._percentageCallback,
            71,
            94
          );
          this._effects["ClawAttack"] = ps.getObjectByName("ClawAttack")!;
          this._effects["Bang"] = ps.getObjectByName("Bang")!;
          this._effects["Boom"] = ps.getObjectByName("Boom")!;
          this._effects["QuestionsMark"] = ps.getObjectByName("QuestionsMark")!;
          this._effects["HealOnce"] = ps.getObjectByName("HealOnce")!;
          this._effects["HeartPoof"] = ps.getObjectByName("HeartPoof")!;
          this._effects["Feed"] = ps.getObjectByName("Feed")!;
          this._effects["LevelUp"] = ps.getObjectByName("LevelUp")!;

          this._textures["Food1"] = await LoadTexturePromise(
            TEXTURE_URLS["food1"],
            this._percentageCallback,
            94,
            95
          );
          this._textures["Food2"] = await LoadTexturePromise(
            TEXTURE_URLS["food2"],
            this._percentageCallback,
            95,
            96
          );
          this._textures["Food3"] = await LoadTexturePromise(
            TEXTURE_URLS["food3"],
            this._percentageCallback,
            96,
            97
          );
          break;
        }
        case "stage3":
          const owlObject = (await GLTFLoaderPromise(
            MODEL_URLS["owl"],
            this._percentageCallback,
            31,
            97
          ));
          this._models.character = owlObject.scene.children[0];
          this._animations.character = owlObject.animations[0];
          break;
        default:
          break;
      }

      this._textures.sceneEnv = await TextureLoaderPromise(
        `${CDN_GATEWAY_BASE}/images/bg-png.png`,
        this._percentageCallback,
        97,
        98
      );
      this._textures.bgTop = await TextureLoaderPromise(
        `${CDN_GATEWAY_BASE}/images/bg-top.webp`,
        this._percentageCallback,
        99,
        100
      );
      this.initModels();
      this.initAnimations();
      if (this._gameStage !== "stage1") {
        this.modelUpdateChecker(this._trait, this._stringLights, true);
      }
      resolve(true);
    });
  }

  initModels() {
    switch (this._gameStage) {
      case "stage1":
        this._models.character.position.set(0.3, 0.2, 0.1);
        break;
      case "stage2":
        if (this._models.character && this._models.dummy) {
          this._models.character.position.set(0, -1.458, 2.365);
          this._models.dummy.position.set(0, -114, -18.365);
          this._effects["ClawAttack"].position.add(this._models.dummy.position);
          this._effects["ClawAttack"].scale.multiplyScalar(1.5);
          this._effects["Bang"].position.add(this._models.dummy.position);
          this._effects["Boom"].position.add(this._models.dummy.position);
          this._effects["HealOnce"].position.add(this._models.character.position);
          this._effects["HeartPoof"].position.add(
            this._models.character.position
          );
          this._effects["Feed"].position.add(this._models.character.position);
          this._effects["QuestionsMark"].position.add(
            this._models.character.position
          );
        }
        break;

      case "stage3":
        this._models.character.position.set(0.62, -1.6, 1.565);
        break;
      default:
        break;
    }
  }

  initAnimations() {
    if (!this._animations.character) return;
    // this._animationClips = {};

    TREE_ANIMATION_NAMES.forEach((name: string) => {
      this._animationClips.tree[name] = THREE.AnimationUtils.subclip(
        this._animations.tree,
        name,
        TREE_ANIMATION_TIMELINE[name].startFrame,
        TREE_ANIMATION_TIMELINE[name].endFrame,
        30
      );
    });

    switch (this._gameStage) {
      case "stage1":
        EGG_ANIMATION_NAMES.forEach((name: string) => {
          this._animationClips.character[name] = THREE.AnimationUtils.subclip(
            this._animations.character,
            name,
            EGG_ANIMATION_TIMELINE[name].startFrame,
            EGG_ANIMATION_TIMELINE[name].endFrame,
            30
          );
        });
        break;
      case "stage2":
        DUMMY_ANIMATION_NAMES.forEach((name: string) => {
          this._animationClips.dummy[name] = THREE.AnimationUtils.subclip(
            this._animations.dummy,
            name,
            DUMMY_ANIMATION_TIMELINE[name].startFrame,
            DUMMY_ANIMATION_TIMELINE[name].endFrame,
            30
          );
        });
        OWL_ANIMATION_NAMES.forEach((name: string) => {
          this._animationClips.character[name] = THREE.AnimationUtils.subclip(
            this._animations.character,
            name,
            OWL_ANIMATION_TIMELINE[name].startFrame,
            OWL_ANIMATION_TIMELINE[name].endFrame,
            30
          );
        });
        break;
      case "stage3":
        OWL_ANIMATION_NAMES.forEach((name: string) => {
          this._animationClips.character[name] = THREE.AnimationUtils.subclip(
            this._animations.character,
            name,
            OWL_ANIMATION_TIMELINE[name].startFrame,
            OWL_ANIMATION_TIMELINE[name].endFrame,
            30
          );
        });
        break;
      default:
        break;
    }
  }

  upgradeModel(upStage: string) {
    this._gameStage = upStage;

    return new Promise(async (resolve, reject) => {
      switch (this._gameStage) {
        case "stage2":
          const owlSmallObject = (await GLTFLoaderPromise(
            MODEL_URLS["owl"],
            this._percentageCallback,
            31,
            70
          ));
          this._models.character = owlSmallObject.scene.children[0];
          this._animations.character = owlSmallObject.animations[0];
          const dummyObject = (await GLTFLoaderPromise(
            MODEL_URLS["dummy"],
            this._percentageCallback,
            71,
            100
          ));
          this._models.dummy = dummyObject.scene.children[0];
          this._animations.dummy = dummyObject.animations[0];

          break;
        case "stage3":
          const owlBigObject = (await GLTFLoaderPromise(
            MODEL_URLS["owl"],
            this._percentageCallback,
            31,
            100
          ));

          this._models.character = owlBigObject.scene.children[0];
          this._animations.character = owlBigObject.animations[0];
          break;
        default:
          break;
      }

      this.initModels();
      this.initAnimations();

      resolve(true);
    });
  }

  modelUpdateChecker(
    trait: TraitsInfo,
    stringLight: string | undefined,
    initLoading?: boolean | undefined
  ) {
    if (initLoading || this._stringLights !== stringLight) {
      this._stringLights = stringLight;
      this.setStringLight();
    }

    if (initLoading || this._trait !== trait) {
      // console.log(this._attributes, this._attributesArray);

      this._attributesArray.forEach((attribute: any) => {
        attribute.visible = false;
      });

      if (trait.chest) {
        const chestMeshNames = chestData[trait.chest];
        chestMeshNames.forEach((name: string) => {
          if (this._attributes[name]) {
            this._attributes[name].visible = true;
          } else {
            console.warn(`Attribute ${name} is undefined.`);
          }
        });
      }

      this.setTexture(
        "T_Eyes",
        `${MODEL_URLS.textures}/Eyes/${trait.eyes}.png`,
      );
      this.setTexture(
        "T_Head",
        `${MODEL_URLS.textures}/Head/${trait.body}.png`,
      );
      this.setTexture(
        "T_Body",
        `${MODEL_URLS.textures}/Body/${trait.body}.png`,
      );
      this.setTexture(
        "T_Wings",
        `${MODEL_URLS.textures}/Wings/${trait.body}.png`,
      );
    }
  }

  setTexture(matType: string, imgUrl: string) {
    // Ensure that the material exists before attempting to set the texture
    if (!this._materials || !this._materials[matType]) {
      console.warn(`Material ${matType} is not defined in _materials.`);
      return;
    }

    new THREE.TextureLoader().load(imgUrl, (texture: THREE.Texture) => {
      if (!texture) return;
      texture.flipY = false;
      texture.colorSpace = THREE.SRGBColorSpace;

      const originalMap = this._materials[matType].map;
      this._materials[matType].map = texture;

      if (originalMap) {
        originalMap.dispose();
      }
    });
  }

  setStringLight() {
    const setVisibility = (decorationName: string, visibility: boolean) => {
      if (this._decorations[decorationName]) {
        this._decorations[decorationName].visible = visibility;
      } else {
        console.warn(`Decoration ${decorationName} is undefined.`);
      }
    };

    if (this._stringLights) {
      setVisibility('Garaland_low', true);

      if (this._stringLights === "String Lights") {
        setVisibility('Lights_low', true);

        setVisibility('Lights_blue_low', false);
        setVisibility('Lights_green_low', false);
        setVisibility('Lights_red_low', false);
        setVisibility('Lights_yellow_low', false);
      } else if (this._stringLights === "Color String Lights") {
        setVisibility('Lights_low', false);

        setVisibility('Lights_blue_low', true);
        setVisibility('Lights_green_low', true);
        setVisibility('Lights_red_low', true);
        setVisibility('Lights_yellow_low', true);
      }
    } else {
      setVisibility('Garaland_low', false);
      setVisibility('Lights_low', false);
      setVisibility('Lights_blue_low', false);
      setVisibility('Lights_green_low', false);
      setVisibility('Lights_red_low', false);
      setVisibility('Lights_yellow_low', false);
    }
  }

  getModel(type: number) {
    return this._models[type];
  }
}

export default AssetsManager;
