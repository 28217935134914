import * as THREE from "three";
import gsap from "gsap";

import * as SceneSetup from "./SceneSetting";
// @ts-ignore
import { CAMERA_PROPS, RENDERER_PROPS } from "../../../constants/rendering";
import { disposeMesh } from "../../../utils/three";
import AssetsManager from "../AssetsManager";
import {
  Clock,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
} from "three";
import { ParticleSystemManager } from "./ParticleSystemManager";
import RendererStats from "three-webgl-stats";
import Stats from "stats.js";

let stats: Stats | null = null;

if (process.env.REACT_APP_DEBUG_WEBGL) {
  stats = new Stats();
  document.body.appendChild(stats.dom);
}

let aspectWidth = window.innerWidth;
let aspectHeight = window.innerHeight;

export class SceneRenderer {
  _renderer!: WebGLRenderer;
  _camera!: PerspectiveCamera;
  _clock!: Clock;
  _scene!: Scene;
  _topPlane: any;
  _bottomPlane: any;
  _camControl: any;
  _gridHelper: THREE.GridHelper;
  _models: any;
  _particleSystemManager: ParticleSystemManager;
  _gameActionManagerCallback: any;
  _gameStage: string;
  _light: any;
  _switchAnim: any;
  _rendererStats: any;
  _sceneTexture: any;
  _topBgTexture: any;
  _botBgTexture: any;
  private _trainMode: boolean;

  constructor(
    assetsManager: AssetsManager,
    gameActionManagerCallback: any,
    gameStage: string,
    trainMode: boolean
  ) {
    this._clock = new THREE.Clock();

    this._gridHelper = new THREE.GridHelper();
    if (process.env.REACT_APP_DEBUG_WEBGL) {
      this._rendererStats = new RendererStats();
    }
    this._models = assetsManager._models;
    this._particleSystemManager = new ParticleSystemManager(
      assetsManager._effects,
      assetsManager._textures
    );
    this._sceneTexture = assetsManager._textures.sceneEnv;
    this._topBgTexture = assetsManager._textures.bgTop;
    this._botBgTexture = assetsManager._textures.temple;
    this._gameActionManagerCallback = gameActionManagerCallback;

    this._gameStage = gameStage;
    this._trainMode = trainMode;

    this.initialize();
  }

  initRenderer() {
    this._renderer = SceneSetup.renderer({ antialias: true, alpha: false });
    this._renderer.setSize(aspectWidth, aspectHeight);
    this._renderer.setPixelRatio(window.devicePixelRatio);
    this._renderer.toneMapping = RENDERER_PROPS.toneMapping;
    this._renderer.toneMappingExposure = 1;
    this._renderer.shadowMap.enabled = false;
    this._renderer.shadowMap.type = THREE.PCFShadowMap;
    this._renderer.shadowMap.autoUpdate = false;

    this._renderer.domElement.style.position = "absolute";
    this._renderer.domElement.style.top = "0px";
    if(process.env.REACT_APP_DEBUG_WEBGL) {
      this._rendererStats.domElement.style.position = "absolute";
      this._rendererStats.domElement.style.left = "0px";
      this._rendererStats.domElement.style.bottom = "0px";
      this._rendererStats.domElement.style.zIndex = 1;
      document.body.appendChild(this._rendererStats.domElement);
    }
  }

  initScene() {
    this._scene = SceneSetup.scene(this._sceneTexture);
    this._topPlane = SceneSetup.TopPlane(this._topBgTexture);
    if (this._gameStage === "stage2" || this._gameStage === "stage3") {
      this._bottomPlane = SceneSetup.BottomPlane(this._botBgTexture);
      if (this._models?.tree?.children[3]) {
        this._models.tree.children[3].visible = false;
      }
      
      this._gameStage === "stage2" &&
        this._models.character.scale.set(0.5, 0.5, 0.5);
        this._topPlane.position.set(-1,-2,-15);
    }
    this._particleSystemManager.init(
      this._scene,
      this._gameStage,
      this._trainMode
    );
    this._scene.add(this._topPlane);
    this._scene.add(this._models.tree);
    this._scene.add(this._models.character);
    this._renderer.shadowMap.needsUpdate = true;
  }

  initCamera() {
    this._camera = SceneSetup.camera(aspectWidth, aspectHeight);

    const toPos =
      this._gameStage === "stage1"
        ? CAMERA_PROPS.Step1Position
        : CAMERA_PROPS.Step2Position;
    gsap.fromTo(
      this._camera.position,
      {
        x: CAMERA_PROPS.initPosition.x,
        y: CAMERA_PROPS.initPosition.y,
        z: CAMERA_PROPS.initPosition.z,
      },
      {
        x: toPos.x,
        y: toPos.y,
        z: toPos.z,
        duration: 3,
        onUpdate: () => {
          this._camera.lookAt(0, 0, 0);
        },
        onComplete: () => {},
        onStart: () => {
          console.log("startgame"); // loading endpoint
          this._gameActionManagerCallback("startgame");
        },
      }
    );
  }

  initLights() {
    this._scene.add(SceneSetup.HemiLight());
    this._light = SceneSetup.SpotLight();
    this._scene.add(this._light);

    const pointLight = new THREE.PointLight(0xffffff, 1, 100);
    pointLight.position.set(0, 5, 0);
    this._scene.add(pointLight);
  }

  removeCharacter() {
    disposeMesh(this._models.character);
    this._scene.remove(this._models.character);
  }

  onResize() {
    aspectWidth = window.screen.width;
    aspectHeight = window.screen.height;
    this._camera.aspect = aspectWidth / aspectHeight;
    this._camera.updateProjectionMatrix();
    this._renderer.setSize(aspectWidth, aspectHeight);
  }

  getScene() {
    return this._scene;
  }

  getCamera() {
    return this._camera;
  }

  addGrid() {
    this.getScene().add(this._gridHelper);
  }

  removeGrid() {
    this.getScene().remove(this._gridHelper);
  }

  initialize() {
    this.initRenderer();
    this.initCamera();
    this.initScene();
    this.initLights();
    window.addEventListener("resize", this.onResize.bind(this), false);
    // window.addEventListener(
    //   "orientationchange",
    //   this.onResize.bind(this),
    //   false
    // );
  }

  stageUp(upStage: string) {
    this._gameStage = upStage;

    this.changeCharacter();
    this.changeCamera();
  }

  // updateModel(item: string) {
  //   this._models.character.traverse((object: THREE.Object3D) => {
  //     if (object instanceof THREE.SkinnedMesh) {
  //       if (object.name === "Owl_Feather_1_low") {
  //         new THREE.TextureLoader().load(
  //           `assets/textures/Textures_Skins_Owl/Owl_low_T_Wings_BaseColor_${item}.1001.png`,
  //           (texture: THREE.Texture) => {
  //             texture.flipY = false;
  //             object.material.map = texture;
  //           }
  //         );
  //       }
  //     }
  //   });
  // }

  changeCharacter() {
    if (this._gameStage === "stage2" || this._gameStage === "stage3") {
      this._models.tree.children[3].visible = false;
      this._gameStage === "stage2" &&
        this._models.character.scale.set(0.5, 0.5, 0.5);
    }
    this._scene.add(this._models.character);
  }

  changeCamera() {
    gsap.to(this._camera.position, {
      x: CAMERA_PROPS.Step2Position.x,
      y: CAMERA_PROPS.Step2Position.y,
      z: CAMERA_PROPS.Step2Position.z,
      duration: 3,
      onUpdate: () => {
        this._camera.lookAt(0, 0, 0);
      },
    });
  }

  switchTrainMode(trainMode: boolean) {
    if (this._switchAnim) this._switchAnim.pause();

    if (trainMode) {
      this._switchAnim = gsap.to(this._camera.position, {
        x: CAMERA_PROPS.trainModePosition.x,
        y: CAMERA_PROPS.trainModePosition.y,
        z: CAMERA_PROPS.trainModePosition.z,
        duration: 2,
        ease: "power4.inOut",
        onStart: () => {
          this._scene.add(this._bottomPlane);
          this._scene.add(this._models.dummy);
        },
        onComplete: () => {
          this._scene.remove(this._topPlane);
          this._scene.remove(this._models.tree);
          this._scene.remove(this._models.character);
          this._switchAnim = null;
        },
      });
      gsap.to(".daytime", {
        backgroundPosition: "20% 100%",
        ease: "power4.inOut",
        duration: 2.2,
      });
    } else {
      gsap.to(".daytime", {
        backgroundPosition: "20% 0",
        ease: "power4.inOut",
        duration: 2.2,
      });
      this._switchAnim = gsap.to(this._camera.position, {
        x: CAMERA_PROPS.Step2Position.x,
        y: CAMERA_PROPS.Step2Position.y,
        z: CAMERA_PROPS.Step2Position.z,
        duration: 2,
        ease: "power4.inOut",
        onStart: () => {
          this._scene.add(this._topPlane);
          this._scene.add(this._models.tree);
          this._scene.add(this._models.character);
        },
        onComplete: () => {
          this._scene.remove(this._bottomPlane);
          this._scene.remove(this._models.dummy);
          this._switchAnim = null;
        },
      });
    }
  }

  render() {
    if (stats) {
      stats.begin();
    }

    this._particleSystemManager.update(this._clock.getDelta());
    this._renderer.render(this._scene, this._camera);
    if(process.env.REACT_APP_DEBUG_WEBGL) {
      this._rendererStats.update(this._renderer);
    }
    if (stats) {
      stats.end();
    }
  }
}
