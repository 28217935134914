import { useNavigate } from "react-router-dom";
import {STAGE2, STAGE3} from "../config";

export const useNavigateByExp = () => {
    const navigate = useNavigate();
    return (experience: number, setGameStage: any) => {
        if (experience >= STAGE3) {
            navigate("/stage3");
            if(setGameStage) {
                setGameStage("stage3");
            }
        } else if (experience >= STAGE2) {
            navigate("/stage2");
            if(setGameStage) {
                setGameStage("stage2");
            }
        } else if (experience >= 0) {
            navigate("/stage1");
            if(setGameStage) {
                setGameStage("stage1");
            }
        }
    };
};
