import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import { ShopStore, UserStore } from "../../store";
import { InventoryInfo, GoodType } from "../../types/interface";
import { REACT_APP_BACKEND_URL } from "../../config";
import IconLeft from "../../assets/icon-left.svg";
import useAnimation from "../../hooks/useAnimation";
import {useNavigateByExp} from "../../utils/levels";
import WebApp from "@twa-dev/sdk";
import MainButton from "../../components/MainButton";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
  gap: 32px;
`;

export const StoreItemDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { userInfo, setUserInfo } = UserStore((state) => ({ userInfo: state.user, setUserInfo: state.setUser }));
  const { findItem } = ShopStore((state) => ({findItem: state.findItem}));
  const [item, setItem] = useState<GoodType | null>(null);
  const [count, setCount] = useState<number>(1);
  const itemInInventory = userInfo.inventories?.find(
      (item: InventoryInfo) => item.id === item.id
  );
  const animationOptions = {
    from: { opacity: 0, y: 20 },
    to: { duration: 1, opacity: 1, y: 0, ease: 'power3.out', stagger: 0.2 }
  };
  const tg = window.Telegram.WebApp;
  const elementRef = useAnimation(animationOptions);
  const init = () => {
    const itemType = params.slug + "s";
    const id = parseInt(params.id!);
    const temp = findItem(itemType, id);
    setItem(temp);
  };

  const increase = () => {
    if (item?.price !== undefined) {
      if(params.slug !== "egg") {
        if (userInfo.coins > (item?.price * count)) setCount(count + 1);
      } else {
        setCount(count + 1);
      }
    }
  } // increase
  const decrease = () => {
    if (count > 1) setCount(count - 1);
  }

  const buyItem = async (type: string | null | undefined) => {
    console.log(type);
    if (type === "egg"){
      console.log('STARS');
    }
    try {
      const res = await axios({
        method: "post",
        url: `${REACT_APP_BACKEND_URL}/buy-goods/`,
        data: {good_id: item?.id, good_count: count},
        headers: {Authorization: `Bearer ${userInfo.token}`}
      });

      // get inventory
      let temp: InventoryInfo[] = [];
      const {data} = await axios({
        method: "get",
        url: `${REACT_APP_BACKEND_URL}/inventory/`,
        headers: {"Authorization": `Bearer ${userInfo.token}`}
      });

      data.forEach((item: any) => {
        temp.push({
          id: item.id,
          item: item.item,
          itemCount: item.item_count,
          isEquipped: item.is_equipped,
        });
      })
      setUserInfo({...userInfo, inventories: temp });
      toast.success("Item bought successfully");
    } catch (error) {
      console.error("Error buying item:", error);
      toast.error("Item purchase failed");
    }
  }

  useEffect(() => {
    init();
  }, []);


  WebApp.BackButton.onClick(()=>{
    navigate('/store');
  });

  return (
    <Wrapper className="bg-gradient-to-t from-[#e8b20934] via-[#00000036] to-[#000] backdrop-blur-xl">
      <div className="w-full ref={elementRef}">
        <div className="flex gap-2 mb-3">
          <button className="w-4 h-full pointer-events-auto" onClick={() => navigate(-1)}><img src={IconLeft} alt="IconLeft" /></button>
        </div>
        <div className="flex flex-col justify-center items-center gap-6 px-2">
          <div className="w-full px-4">
            <div className="aspect-square w-full h-full bg-transparent rounded-[36px]">
              <img className="aspect-square w-full h-full rounded-[36px]" src={item?.img} alt="item" />
            </div>
          </div>
          <div className="w-full">
            <h4 className="text-2xl font-bold text-white mb-1">
              {item?.name}
              <span>You own: {itemInInventory?.name}</span>
            </h4>
            <p className="text-sm font-normal text-white">{item?.description}</p>
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="flex justify-center items-center gap-2">
              {params.slug === "egg" ?
              <img src={'https://notwise.b-cdn.net/icons/stars.svg'} width={18} height={18}/>
                  :
              <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18}/>
              }
              <p className="text-xl font-bold text-white">{item?.price}</p>
            </div>
            <div className="flex justify-center items-center bg-[#ebe1e530] rounded-lg">
              <button 
                className="flex justify-center items-center w-10 h-10 text-xl font-normal text-white pointer-events-auto" 
                onClick={decrease}
              >-</button>
              <p className="w-[40px] text-base font-bold text-white text-center px-2">{count}</p>
              <button 
                className="flex justify-center items-center w-10 h-10 text-xl font-normal text-white pointer-events-auto"
                onClick={increase}
              >+</button>
            </div>
          </div>

        </div>
      </div>
      <div className="w-full my-4">
        {params.slug !== "egg" &&
        <div className="flex justify-center items-center gap-2 my-5 w-full">
          <span className="text-sm font-medium text-white">Balance after purchase:</span>
          <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18}/>
          <p className="text-sm font-bold text-white">{item?.price !== undefined ? userInfo.coins - (item?.price * count) : "N/A"}</p>
        </div>
        }
        <MainButton isLoading={false} btnText={`Buy for ${item?.price! * count} ${params.slug !== "egg" ? "coins" : "stars"}`} onClick={() => buyItem(params.slug)}/>
      </div>
    </Wrapper>
  );
};

export default StoreItemDetails;
