import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {ShopStore, UserStore} from "../../store";
import IconLeft from "../../assets/icon-left.svg";
import Right from "../../assets/icon-right.svg";
import Item from "../../components/StoreItem";
import {useNavigateByExp} from "../../utils/levels";
import WebApp from "@twa-dev/sdk";
import { GoodsType, GoodType } from "../../types/interface";
import MainNav from "../../components/MainNav";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 1rem;
  flex-wrap: wrap;
`;

const TabButton = styled.button<{ active: boolean }>`
  padding: 6px 16px;
  background: ${(props) => (props.active ? "#FFE4A83F" : "#FFE4A820")};
  color: #fff;
  border: 1px solid ${(props) => (props.active ? "#FFFAF053" : "transparent")};
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  &:focus {
    outline: none;
  }
`;

export const Store = () => {
  const navigate = useNavigate();
  const { foods, decorations, eggs, medicines, skins } = ShopStore((state) => ({
    foods: state.foods,
    decorations: state.decorations,
    eggs: state.eggs,
    medicines: state.medicines,
    skins: state.skins,
  }));

  const [activeTab, setActiveTab] = useState<GoodsType>("food");

  const renderItems = (items: GoodType[], itemType: GoodsType) => {
    return items && items.length > 0 ? (
        items.map((item, index) => <Item key={index} itemType={itemType} item={item} />)
    ) : (
        <p className="text-sm leading-3 text-white/[.70]">No {itemType}s yet</p>
    );
  };
  const navigateByExp = useNavigateByExp();
  const {userInfo, setUser} = UserStore((state) => ({userInfo: state.user, setUser: state.setUser}));

  WebApp.BackButton.onClick(()=>{
    WebApp.BackButton.hide();
    navigateByExp(userInfo.totalExp, null);
  });
  return (
      <div className="block w-full h-full">
      <Wrapper className="bg-gradient-to-t from-[#e8b20934] via-[#00000036] to-[#000] backdrop-blur-xl overflow-y-auto scrollable no-scrollbar">
        <div className="w-full pb-20">
          <div className="flex flex-col justify-center items-center mt-10 mb-5">
            <img src={"https://notwise.b-cdn.net/icons/icon-store.png"} width={80} height={80} />
          </div>
          <div className="flex flex-col items-center justify-center w-full mb-1">
            <h6 className="text-2xl font-bold text-white">
              <span className="text-[#ffd323] empires">Wise</span> Store
            </h6>
            <span className="mt-1 font-medium text-white/[70%] text-sm">Goods for pets and more</span>
          </div>
          <Tabs>
            <TabButton className="pointer-events-auto flex items-center text-sm" active={activeTab === "food"} onClick={() => setActiveTab("food")}>
              <img src="https://notwise.b-cdn.net/icons/criossant.png" className="w-4 h-auto mr-2"/> Food
            </TabButton>
            <TabButton className="pointer-events-auto flex items-center text-sm" active={activeTab === "decoration"} onClick={() => setActiveTab("decoration")}>
              <img src="https://notwise.b-cdn.net/icons/ny.png" className="w-4 h-auto mr-2"/> Deco
            </TabButton>
            <TabButton className="pointer-events-auto flex items-center text-sm" active={activeTab === "medicine"} onClick={() => setActiveTab("medicine")}>
              <img src="https://notwise.b-cdn.net/icons/pill.png" className="w-4 h-auto mr-2"/> Med
            </TabButton>
            <TabButton className="pointer-events-auto flex items-center text-sm" active={activeTab === "skin"} onClick={() => setActiveTab("skin")}>
              <img src="https://notwise.b-cdn.net/icons/pill.png" className="w-4 h-auto mr-2"/> Skin
            </TabButton>
            <TabButton className="pointer-events-auto flex items-center text-sm" active={activeTab === "egg"} onClick={() => setActiveTab("egg")}>
              <img src="https://notwise.b-cdn.net/icons/egg.png" className="w-4 h-auto mr-2"/> Eggs
            </TabButton>
          </Tabs>
          <div className="flex flex-col justify-center items-center my-8 gap-2 px-4">
            <div className="w-full mb-3">
              {activeTab === "food" && (
                  <>
                    <div className="overflow-x-auto overflow-y-hidden">
                      <div className="w-full flex flex-wrap justify-start gap-3">{renderItems(foods || [], "food")}</div>
                    </div>
                  </>
              )}
              {activeTab === "decoration" && (
                  <>
                    <div className="overflow-x-auto overflow-y-hidden">
                      <div className="w-full flex flex-wrap justify-start gap-3">{renderItems(decorations || [], "decoration")}</div>
                    </div>
                  </>
              )}
              {activeTab === "medicine" && (
                  <>
                    <div className="overflow-x-auto overflow-y-hidden">
                      <div className="w-full flex flex-wrap justify-start gap-3">{renderItems(medicines || [], "medicine")}</div>
                    </div>
                  </>
              )}
              {activeTab === "skin" && (
                  <>
                    <div className="overflow-x-auto overflow-y-hidden">
                      <div className="w-full flex flex-wrap justify-start gap-3">{renderItems(skins || [], "skin")}</div>
                    </div>
                  </>
              )}
              {activeTab === "egg" && (
                  <>
                    <div className="overflow-x-auto overflow-y-hidden">
                      <div className="w-full flex flex-wrap justify-start gap-3">{renderItems(eggs || [], "egg")}</div>
                    </div>
                  </>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
        <MainNav userExp={userInfo.totalExp} skillPoints={userInfo.skillPoints} isBranch={false}  isTrainMode={false} setIsNeedShown={false} setTrainMode={false} />
      </div>
  );
};

export default Store;
