import * as THREE from "three";

export const RENDERER_PROPS = {
  antialias: true,
  outputColorSpace: THREE.SRGBColorSpace,
  toneMapping: THREE.ACESFilmicToneMapping,
  shadowMapEnable: true,
};

export const CAMERA_PROPS = {
  fov: 45,
  near: 0.1,
  far: 1000,
  initPosition: {
    x: 5,
    y: 16,
    z: 30,
  },
  Step1Position: {
    x: -5,
    y: 16,
    z: 35,
  },
  Step2Position: {
    x: 0,
    y: 5,
    z: 35,
  },
  trainModePosition: {
    x: 0,
    y: -100,
    z: 35,
  },
};

export const SCENE_PROPS = {
  fog: {
    enable: false,
    color: 0xffffff,
    near: 90,
    far: 200,
  },
};
