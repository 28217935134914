import { TextureLoader } from "three";
import { LoadingManager } from "three";
export const TextureLoaderPromise = (
  src: any,
  percentageCallback: Function,
  startPercent: number,
  endPercent: number
) => {
  return new Promise((resolve) => {
    const loadingManager = new LoadingManager();
    loadingManager.onProgress = (
      url: string,
      loaded: number,
      total: number
    ) => {
      percentageCallback(
        Math.ceil(startPercent + (loaded / total) * (endPercent - startPercent))
      );
    };
    const loader = new TextureLoader(loadingManager);
    loader.load(src, function (texture) {
      resolve(texture);
    });
  });
};
