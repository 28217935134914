import { create } from "zustand";

interface GameRef {
  gameRef: any;
  isTouch: boolean;
  load: number;
  gameStage: string;
  trainMode: boolean;
  setGameRef: (gameRef: any) => void;
  setIsTouch: (isTouch: boolean) => void;
  setLoad: (load: number) => void;
  setGameStage: (gameStage: string) => void;
  setTrainMode: (traninMode: boolean) => void;
}

const GameStore = create<GameRef>((set, get) => ({
  gameRef: null,
  isTouch: false,
  load: 0,
  gameStage: "",
  trainMode: false,
  setGameRef: (gameRef) => set({ gameRef }),
  setIsTouch: (isTouch) => set({ isTouch }),
  setLoad: (load) => set({ load }),
  setGameStage: (gameStage) => set({ gameStage }),
  setTrainMode: (trainMode) => set({ trainMode }),
}));

export default GameStore;
