import styled from "styled-components";
import axios from "axios";
import {CDN_GATEWAY_BASE, LEVELS, REACT_APP_BACKEND_URL} from "../../config";
import { Round } from "../../utils/math";
import Progressbar from "../../components/Progressbar";
import UserStore from "../../store/UserStore";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useNavigateByExp} from "../../utils/levels";
import MainNav from "../../components/MainNav";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;
interface LeaderDataItem {
  username: string;
  exp: number;
  coins: number;
}
export const LeaderBird = () => {

  const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();
  const userInfo = UserStore((state) => state.user);
  const [leaderData, setLeaderData] = useState<LeaderDataItem[]>([]);
  const progress = ((LEVELS[userInfo.level] - userInfo.totalExp)/(LEVELS[(userInfo.level + 1)] - LEVELS[userInfo.level])) * -100;
  console.log(userInfo);
  useEffect(() => {
    const fetchLeaderData = async () => {
      try {
          const res = await axios.get(`${REACT_APP_BACKEND_URL}/leaderbord/`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          });
          setLeaderData(res.data);
      } catch (error) {
        console.error("Error fetching leaderbird data:", error);
      }
    };

    fetchLeaderData();
    const tg = window.Telegram.WebApp;
    tg.BackButton.onClick(() => {
      tg.BackButton.hide();
      navigateByExp(userInfo.totalExp, null);
    });
  }, [navigate]);

  return (
      <div className="block w-full h-full">
    <Wrapper className="bg-gradient-to-t from-[#e8b20934] via-[#00000036] to-[#000] backdrop-blur-xl z-2 overflow-y-auto scrollable no-scrollbar">
      <div className="w-full flex flex-col h-screen">
        <div className="flex items-center justify-center w-full mb-1">
          <h6 className="text-2xl font-bold text-white"><span className="text-[#FFB800] empires">EXP</span> Leaderbird</h6>
        </div>
        <div className="flex flex-col justify-center items-center mt-10 mb-5">
          <h1 className="text-5xl flex w-6 h-6">
            {userInfo.level === 0 && <img src="https://notwise.b-cdn.net/icons/egg.png" width={48} height="auto" alt="icon"/>}
            {userInfo.level >= 1 && <img src="https://notwise.b-cdn.net/icons/owling.png" width={48} height="auto" alt="icon"/>}
            {userInfo.level >= 13 && <img src="https://notwise.b-cdn.net/icons/owl.png" width={48} height="auto" alt="icon"/>}
          </h1>
          <span className="mt-5 font-medium text-white/[70%] text-sm">
            {userInfo.level === 0 && "Egg Stage"}
            {userInfo.level >= 1 && "Owling Stage"}
            {userInfo.level >= 13 && "Owl Stage"}
            <b className="text-white">&nbsp;#{userInfo.rank}</b>
          </span>
          <span className="font-medium text-white text-lg"><b>{userInfo.totalExp}</b> <span className="text-white/[70%]">/ {LEVELS[(userInfo.level + 1)]} EXP</span></span>
        </div>
        <div className="w-11/12 flex items-center justify-between mb-20 m-auto rounded-xl px-1">
          <div className="w-2/12 flex items-center">
            <i className="icons8 text-white">&#xf117;</i>
            <span className="text-white font-medium ml-1 text-sm">lvl {(userInfo.level + 1)}</span>
          </div>
          <Progressbar
              blurred={true}
              winking={false}
              barcolor="bg-gradient-to-r from-[#FF6F41] to-[#FEFBE9]"
              ht="min-h-3"
              percent="w"
              spark={Round(progress, 1) > 20}
              value={userInfo.totalExp}
              maxvalue={LEVELS[userInfo.level + 1]}
              customStyle={{ width: `${Round(progress, 1)}%` }}
          />
        </div>

        <div className="h-auto flex flex-col flex-grow">
          {
            leaderData && leaderData.length > 0 ? leaderData.map((item:any, index: number) => (
              <div className={`grid grid-cols-12 items-center gap-2 mb-5 ${Number(item?.tg_id) !== Number(userInfo?.tg_id) ? "opacity-50": "opacity-100"}`} key={index}>
                <div className={`flex col-span-2 justify-center ${
                  index === 0 ? "text-yellow-500":
                      index === 1 ? "text-gray-400":
                         index === 2 ? "text-yellow-700": 
                             "text-white/[50%]"
                }`}>
                  #{index+1}
                </div>
                <div className="col-span-5">
                  <div className="flex justify-center gap-2">
                    <h6 className="font-bold text-white">{item.username ? item.username : `ID ${item.tg_id}`}</h6>
                  </div>
                </div>
                <div className="col-span-5 text-center">
                  <div className="flex items-center justify-center gap-2">
                    <h6 className="font-normal text-white">{item.experience} EXP</h6>
                  </div>
                </div>
              </div>  
            ))
                :
                <div className="text-white text-center">Loading&nbsp;
                  <span className="animate-ping">...</span>
                </div>
          }
          <div className="grid grid-cols-12 items-center gap-2 mb-20"></div>
        </div>
      </div>
    </Wrapper>
        <MainNav isBranch={false} isTrainMode={false} setIsNeedShown={false} setTrainMode={false} userExp={userInfo.totalExp} skillPoints={userInfo.skillPoints}/>
      </div>
  );
};

export default LeaderBird;
