import gsap from "gsap";
import {useEffect, useRef, useState} from "react";
import {useGSAP} from "@gsap/react";
import {UserActionAnimationProvider} from "../GameScene/UserActionAnimationProvider/UserActionAnimationProvider";
interface OverflowerTextProps {
  className?: string;
  style?: {};
}
interface Block {
  id: number;
  x: number,
  y: number,
  amount: number
}

const OverflowerText = (props: OverflowerTextProps) => {
  const expIncRef= useRef<HTMLDivElement>(null);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleAddExp = (e: any) => {
    const newBlock = {
      id: Date.now(),
      x: e.detail?.x,
      y: e.detail?.y,
      amount: e.detail?.amount
    };
    setBlocks([...blocks, newBlock]);
  };
  useEffect(() => {
    document.addEventListener("addExp", handleAddExp);
    return () => {
      document.removeEventListener("addExp", handleAddExp);
    }
  });
  /* GSAP */
  const { contextSafe } = useGSAP({ scope: expIncRef });
  const animExp = contextSafe(()=>{
    if (expIncRef.current == null) return;
    gsap.to(expIncRef.current?.lastChild, {y: -200, alpha:0, duration: 1.5});
  })
  useEffect(() => {
    if (blocks.length === 0) return ;
    animExp();
  }, [blocks]);

  useEffect(() => {
    if (blocks.length === 0) return ;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setBlocks([]);
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [blocks]);
  return (
      <div style={props.style} ref={expIncRef}
           className={`overflow-text${props.className ? ` ${props.className}` : ''}`}>
        {
          blocks.map((item, index) => (
            <div className="absolute w-max text-xl text-white font-bold break-keep" style={{left: item.x - 60, top: item.y - 100}} key={index}> {item.amount > 0 ? `+${item.amount}`: 0 } EXP</div>
          ))
        }
      </div>
  )
};

export default OverflowerText;
