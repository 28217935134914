import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { REACT_APP_BACKEND_URL } from "../../config";
import axios from "axios";
import { InventoryInfo } from "../../types/interface";
import { UserStore } from "../../store";

interface InventoryProps {
  token: string;
}

const Inventory = (props: InventoryProps) => {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = UserStore((state) => ({
    userInfo: state.user,
    setUserInfo: state.setUser,
  }));
  const { token } = props;
  const tg = window.Telegram.WebApp;
  // const [inventories, setInventories] = useState<any[]>([]);
  const handleItem = (item: InventoryInfo) => {
    const categories = userInfo.inventories?.filter(
      (one: InventoryInfo) => one.item.type === item.item.type
    );
    if (!categories || categories.length === 0) return;

    const isEquip = categories?.find((item: InventoryInfo) => item.isEquipped);
    if (!isEquip) navigate(`/inventory/details/${item.id}`);
    else {
      navigate(`/inventory/details/${item.id}`);
      // if (!item.isEquipped) return;
      // else navigate(`/inventory/details/${item.id}`);
    }
  };

  // useEffect(() => {
  //     const fetchInventory = async () => {
  //         try {
  //             const { data } = await axios.get(`${REACT_APP_BACKEND_URL}/inventory/`, {
  //                 headers: { "Authorization": `Bearer ${token}` }
  //             });
  //             console.log("Fetched inventory data:", data); // Debug logging
  //             setInventories(data); // Assuming data is an array of ShopItemInfo
  //             setUserInfo({...userInfo, inventories: data});
  //         } catch (error) {
  //             console.error("Failed to fetch inventory", error);
  //         }
  //     };

  //     fetchInventory();
  // }, [token]); // Add token and totalExp as dependencies

  const handleClick = () => {
    console.log("Heat");
  };
  return (
    <>
      <div>
        <div className="grid gap-3 grid-cols-4">
          {userInfo.inventories ? (
            userInfo.inventories.map((item: any, index: number) => (
              <div
                key={index}
                className={`relative rounded-2xl`}
              >
                <button
                  onClick={() => handleItem(item)}
                  className={`${
                    item?.isEquipped === true && "opacity-100"
                  } relative z-1 rounded-2xl bg-transparent w-full h-auto pointer-events-auto`}
                >
                  <img
                    src={item.item.img}
                    className="rounded-2xl w-full h-full"
                    alt="item"
                  />
                </button>
                {item.item_count > 1 && (
                  <span className="absolute z-2 left-1 top-1 bg-gray-800/[.6] rounded-lg font-bold text-xs text-white px-2">
                    x{item.item_count}
                  </span>
                )}
                {item?.item.type === "skin" || item?.item.type === "decoration" ?
                <>
                  {item?.isEquipped === true ?
                    <span
                      className={`absolute z-2 left-1 top-1`}
                    >
                      <img src="https://notwise.b-cdn.net/icons/equi.svg" className={"w-5 min-w-5"} alt={"icon"}/>
                    </span>
                    :
                    <span
                      className={`absolute z-2 left-1 top-1`}
                    >
                      <img src="https://notwise.b-cdn.net/icons/unequi.svg" className={"w-5 min-w-5"} alt={"icon"}/>
                    </span>
                  }
                </>
                :
                ""
                }
              </div>
            ))
          ) : (
            <span className="text-white/[50%]">empty</span>
          )}
        </div>
      </div>
    </>
  );
};

export default Inventory;
