interface ProgressbarProps {
  barcolor: null | string;
  blurred: null | boolean;
  ht: null | string;
  spark: null | boolean;
  percent: null | string;
  winking: null | boolean;
  value: null | number | string;
  maxvalue: null | number | string;
  customStyle?: React.CSSProperties;
}

const Progressbar = (props: ProgressbarProps) => {
  const { blurred, barcolor, spark, winking, ht, percent, value=null, maxvalue=null, customStyle } = props;
  return (
    <div className="w-10/12">
      <div
        className={`flex justify-center ${blurred && 'backdrop-blur'} relative w-full ${ht} bg-[#D9D9D950] rounded-full overflow-hidden`}
      >
        <div
          id="progressBar"
          className={`absolute top-0 left-0 ${percent} h-full ${barcolor} rounded-full transition-width duration-500${spark ? " spark":""}`}
          style={customStyle}
        ></div>
      </div>
    </div>
  );
};
export default Progressbar;
