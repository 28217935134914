import {useCallback, useEffect, useMemo, useRef} from "react";
import userStore from "../store/UserStore";

const UPDATE_INTERVAL = 86400

export const useEnergyUpdate = () => {
  const last_energy_used = userStore(state => state.user.last_energy_used)
  const addEnergy = userStore(state => state.addEnergy)

  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const lastEnergyUsed = useMemo(() => new Date(last_energy_used), []);
  const secondsPassedEnergy = useMemo(() => (new Date().getTime() - lastEnergyUsed.getTime()) / 1000, [lastEnergyUsed]);

  const updateEnergyWithInterval = useCallback(() => {
    const energyToAdd = 1
    addEnergy(energyToAdd)

  }, [addEnergy])

  useEffect(() => {
    const energyToAdd = Math.floor(secondsPassedEnergy / UPDATE_INTERVAL / 1000);

    if (energyToAdd > 0) {
      addEnergy(energyToAdd)
    }
  }, [])

  useEffect(() => {
    const delay = secondsPassedEnergy > UPDATE_INTERVAL ? 0 : UPDATE_INTERVAL - secondsPassedEnergy
    const startInterval = () => {
      intervalRef.current = setInterval(updateEnergyWithInterval, UPDATE_INTERVAL);
    }

    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(startInterval, delay);
    } else {
      startInterval()
    }


    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    };
  }, [secondsPassedEnergy, updateEnergyWithInterval]);
}
