export const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_TEST == "true"
    ? "https://api.masonsplay.com"
    : "https://api.notwise.co";
export const CDN_GATEWAY_BASE = "./assets";

export const STAGE2 = 100000;
export const STAGE3 = 126000;
export const LEVELS: { [key: number]: number } = {
    0: 0,
    1: 100000,
    2: 104000,
    3: 106000,
    4: 108000,
    5: 110000,
    6: 112000,
    7: 114000,
    8: 116000,
    9: 118000,
    10: 120000,
    11: 122000,
    12: 124000,
    13: 126000,
    14: 128000,
    15: 130000,
    16: 132000,
    17: 134000,
    18: 136000,
    19: 138000,
    20: 140000,
    21: 142000,
}
