import { Link } from "react-router-dom";
import styled from "styled-components";
import Progressbar from "../../components/Progressbar";
import Frens from "../../assets/icon-frens.svg";
import Shopcart from "../../assets/icon-shopping.svg";
import User from "../../assets/icon-user.svg";
import Swords from "../../assets/icon-swords.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 28px 20px;
  width: 100%;
  height: 100%;
`;

export const Stage3 = () => {
  return (
    <Wrapper>
      <div className="w-full">
        <div className="relative flex justify-between rounded-xl bg-[#D9D9D960] px-4 py-2 mb-1">
          <div className="rounded-full w-7 h-7 bg-[#D9D9D9]"></div>
          <div className="">
            <h6 className="text-sm leading-4 font-bold text-white">Durove’s Squad</h6>
            <p className="text-xs leading-4 font-normal text-white">21,012,002 Players</p>
          </div>
          <p className="text-xs font-normal text-[#A9A9A9]">TOP 3313</p>
        </div>
        <div className="relative">
          <div className="absolute left-1/2 -translate-x-1/3">
            <h6 className="text-lg font-bold text-white opacity-20 mb-2">+4 EXP</h6>
            <h6 className="flex text-lg font-bold text-white opacity-20 mb-2">
              +1 
              <div className="flex justify-center items-center w-6 h-6 rounded-full bg-[#FFB800] ml-1">
                <div className="coin__front rounded-full w-3.5 h-3.5 bg-[#FF9900]"></div>
              </div>
            </h6>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-5">
          <div className="flex justify-center items-center gap-2 mb-4">
            <div className="flex justify-center items-center w-6 h-6 rounded-full bg-[#FFB800]">
              <div className="coin__front rounded-full w-3.5 h-3.5 bg-[#FF9900]"></div>
            </div>
            <h6 className="text-2xl font-bold text-white">123, 333</h6>
          </div>
          <h6 className="text-lg font-bold text-white mb-2">+25 EXP</h6>
            <div className="w-3/4 mb-4">
              <Progressbar blurred={true} winking={false} spark={true} barcolor="bg-[#FF984E]" ht="min-h-3" percent="w-1/2" value={212} maxvalue={1400} />
            </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="w-1/2 mb-4">
          <Progressbar blurred={true} winking={false} spark={true} barcolor="bg-gradient-to-r from-[#FFF173] to-[#99FFAA]" ht="min-h-2" percent="w-2/3" value={null} maxvalue={null}/>
        </div>
        <div className="w-full flex justify-between bg-[#D9D9D960] rounded-xl px-4 py-2">
          <Link to="/frens" className="flex flex-col items-center">
            <img src={Frens} alt="frens" className="w-5 h-5" />
            <p className="text-xs font-normal text-white">frens</p>
          </Link >
          <Link to="/earn" className="flex flex-col items-center">
            <div className="flex justify-center items-center w-5 h-5 rounded-full bg-[#FFB800] ml-1">
              <div className="coin__front rounded-full w-3 h-3 bg-[#FF9900]"></div>
            </div>
            <p className="text-xs font-normal text-white">coin</p>
          </Link>
          <Link to="/earn" className="flex flex-col items-center">
            <img src={Shopcart} alt="shop" className="w-5 h-5" />
            <p className="text-xs font-normal text-white">shop</p>
          </Link>
          <Link to="/profile" className="flex flex-col items-center">
            <img src={User} alt="profile" className="w-5 h-5" />
            <p className="text-xs font-normal text-white">profile</p>
          </Link>
          <Link to="/swords" className="flex flex-col items-center">
            <img src={Swords} alt="swords" className="w-5 h-5" />
            <p className="text-xs font-normal text-white">fight</p>
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default Stage3;
