interface ThermometerProps {
  barcolor: null | string;
  ht: null | string;
  spark: null | boolean;
  percent: null | string;
  value: null | number | string;
  maxvalue: null | number | string;
  customStyle?: React.CSSProperties;
}

const Thermometer = (props: ThermometerProps) => {
  const { barcolor, spark, ht, percent, value, maxvalue=null, customStyle } = props;
  return (
    <div className="w-1">
      <div
        className={`thermometer flex justify-end backdrop-blur relative w-full ${ht} bg-[#D9D9D950] rounded-full`}
      >
        <div
          id="progressBar"
          className={`absolute bottom-0 left-0 w-full h ${barcolor} rounded-full transition-height duration-500${spark ? " spark":""}`}
          style={customStyle}
        ></div>
      </div>
    </div>
  );
};
export default Thermometer;
