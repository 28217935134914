import {create} from "zustand";
import {UserInfo} from "../types/interface";

const UserStore = create<UserInfo>((set, get) => ({
  user: {
    totalExp: 0,
    temperature: 0,
    energy: 0,
    energyState: 0,
    skillPoints: 0,
    level: 0,
    coins: 0,
    strength: 0,
    agility: 0,
    wisdom: 0,
    endurance: 0,
    hunger: 0,
    tg_id: 0,
    username: null,
    isPremium: false,
    lang: null,
    inventories: null,
    token: '',
    rank: 0,
    health: 0,
    happiness: 0,
    lastFeeding: null,
    lastHeating: null,
    lastEnergyUsed: null,
    squad: null,
  },
  foreigns: null,
  setUser: (payload) => set((state) => ({...state, user: payload})),
  setForeigns: (payload) => set((state) => ({...state, foreigns: payload})),
  updateUser: (amount, energyCost, type) => set((state) => {
    const {energy, totalExp, temperature, happiness} = state.user;

    if (energy >= energyCost) {
      const updates = {
        energy: energy - energyCost,
        totalExp: type === "experience" ? totalExp + amount : totalExp,
        temperature: type === "temperature" ? temperature + amount : temperature,
        happiness: type === "happiness" ? happiness + amount : happiness,
      };

      return {user: {...state.user, ...updates}};
    }
    return state;
  }),
  decreaseTemperature: (temperature: number) => set((state) => ({
    ...state,
    user: {
      ...state.user,
      temperature: Math.max(0, state.user.temperature - temperature),
      last_heating: new Date()
    },
  })),
  addEnergy: (energy: number) => set((state) => ({
    ...state,
    user: {
      ...state.user,
      energy: Math.min(1000, state.user.energy + energy),
      last_energy_used: new Date()
    },
  })),
  updateByUserInteraction: (last_energy_used: number,
                            last_heating: number,
                            temperature: number,
                            action: string,
  ) => set((state) => {
    if (action === "heat") {
      return {
        user: {
          ...state.user,
          last_energy_used: last_energy_used,
          last_heating: last_heating !== 0 ? last_heating : state.user.last_heating,
          temperature: temperature !== 0 ? temperature : state.user.temperature
        },
      };
    } else if (action === "swipeOwl") {
      return {
        user: {
          ...state.user,
          happiness: state.user.happiness + 1
        },
      };
    } else if (action === "feedOwl") {
      return {
        user: {
          ...state.user,
          hunger: state.user.hunger + 1
        },
      };
    }

    return {
      user: {
        ...state.user,
        last_energy_used: last_energy_used,
        last_heating: last_heating !== 0 ? last_heating : state.user.last_heating,
        temperature: temperature !== 0 ? temperature : state.user.temperature
      },
    };
  }),
}));

export default UserStore;
