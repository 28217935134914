import {useEffect, useRef, useState} from "react";
import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";
import Progressbar from "../../components/Progressbar";
import Frens from "../../assets/icon-frens.svg";
import Shopcart from "../../assets/icon-shopping.svg";
import User from "../../assets/icon-user.svg";
import IconLeft from "../../assets/icon-white-left.svg";
import IconTrain from "../../assets/icon-train.svg";
import IconTree from "../../assets/icon-tree.svg";
import IconGift from "../../assets/icon-gift.svg";
import IconRight from "../../assets/icon-white-right.svg";
import { GameStore } from "../../store";
import {tg_haptic} from "../../utils/telegramapi";
import UserStore from "../../store/UserStore";
import {Game} from "../../components/GameScene/game";
import {Round} from "../../utils/math";
import SquadWidget from "../../components/SquadWidget";
import NeedsWidget from "../../components/NeedsWidget";
import InventoryWidget from "../../components/InventoryWidget";
import UpdateBarWidget from "../../components/UpdateBarWidget";
import AmbientSound from "../../components/sounds/AmbientSound";
import MainNav from "../../components/MainNav";
import DifficultyWidget from "../../components/DifficultyWidget";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 28px 20px;
  width: 100%;
  height: 100%;
`;

export const Stage2 = () => {
  const userInfo = UserStore((state) => state.user);
  const isTrainMode = GameStore.getState().trainMode;
  const setTrainMode = GameStore((state) => state.setTrainMode);
  const [isNeedShown, setIsNeedShown] = useState(false);
  const [isUpdateWidgetShown, setIsUpdateWidgetShown] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({modifier: '', amount: 0});
  const [isInventoryWidgetShown, setIsInventoryWidgetShown] = useState(false);
  const [isDifficultyShown, setIsDifficultyShown] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const previousValues = useRef({
    hunger: userInfo.hunger,
    happiness: userInfo.happiness,
    health: userInfo.health
  });

  useEffect(() => {
    if (!isInventoryWidgetShown && !isTrainMode) {
      if (userInfo.hunger < 1 || userInfo.health < 1 || userInfo.happiness < 1) {
        const timeoutId = setTimeout(() => {
          if (userInfo.hunger < 1 || userInfo.health < 1 || userInfo.happiness < 1) {
            setIsNeedShown(true);
          }
        }, 1500);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [isNeedShown, isInventoryWidgetShown, isTrainMode, userInfo.hunger, userInfo.health, userInfo.happiness]);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      if (!isTrainMode) {
        let changedValue = null;
        if (userInfo.hunger !== previousValues.current.hunger) {
          changedValue = {modifier: 'hunger', amount: userInfo.hunger};
        } else if (userInfo.happiness !== previousValues.current.happiness) {
          changedValue = {modifier: 'happiness', amount: userInfo.happiness};
        } else if (userInfo.health !== previousValues.current.health) {
          changedValue = {modifier: 'health', amount: userInfo.health};
        }
        if (changedValue) {
          setUpdateInfo(changedValue);
          setIsUpdateWidgetShown(true);
          previousValues.current = {
            hunger: userInfo.hunger,
            happiness: userInfo.happiness,
            health: userInfo.health
          };
        }
      }
    }
  }, [userInfo.hunger, userInfo.happiness, userInfo.health, isTrainMode]);

  const tg = window.Telegram.WebApp;
  return (
      <Wrapper>
        <div className="w-full">
          <SquadWidget squadInfo={userInfo.squad}/>
          <Link
              to="/leaderbird"
              onClick={() => {tg_haptic.impactOccurred("light"); tg.BackButton.show()}}
              className="flex flex-col items-center pointer-events-auto"
          >
            <div className="leaderbird w-max flex justify-between text-white">
              <img
                  className="opacity-50"
                  src="https://notwise.b-cdn.net/icons/wingl.svg"
                  width={24}
                  height={24}
                  alt={"icon"}
              />
              <span className="mx-2 font-medium opacity-90">
              #{userInfo.rank}
            </span>
              <img
                  className="opacity-50"
                  src="https://notwise.b-cdn.net/icons/wingr.svg"
                  width={24}
                  height={24}
                  alt={"icon"}
              />
            </div>
            <div className="mt-1 flex justify-between">
              <div className="text-white font-medium">🐣&nbsp;Owling Stage <span className="font-semibold text-sm text-white/[60%] ml-1">LVL <span className="text-white">{userInfo.level}</span></span></div>
              <i className="icons8 text-white/[60%] -rotate-90 ml-1">&#xf116;</i>
            </div>
          </Link>
          <div className="flex w-full mt-3">
            {isUpdateWidgetShown &&
                <UpdateBarWidget modifier={updateInfo.modifier} amount={updateInfo.amount}/>
            }
          </div>
          <Outlet />
        </div>
        <div className="w-3/12 absolute flex items-center justify-end top-2/4 -mt-24 mr-5">
          {isNeedShown &&
              <NeedsWidget need="feed" setIsNeedShown={setIsNeedShown} setIsInventoryShown={setIsInventoryWidgetShown}/>
          }
        </div>
        <div className="w-full flex flex-col items-center">
          {isInventoryWidgetShown &&
              <InventoryWidget need="food" token={userInfo.token} setIsShown={setIsInventoryWidgetShown}/>
          }
          {isDifficultyShown &&
            <DifficultyWidget setIsShown={setIsDifficultyShown} setTrainMode={setTrainMode} setIsNeedShown={setIsNeedShown} />
          }
          <div className="w-80 flex items-center justify-between mb-20 rounded-xl px-1">
            <div className="w-4/12 flex items-center">
              <span className="text-2xl">⚡</span>
              <span className="text-white font-medium ml-1 text-sm leading-3">
            <span className="text-xl font-bold">{userInfo.energy}</span>
            <br />
            <span className="opacity-70">/ 1000</span>
          </span>
            </div>
            <Progressbar
                blurred={true}
                winking={false}
                barcolor="bg-gradient-to-r from-[#FF6F41] via-[#FFAD42] to-[#FFE34F]"
                ht="min-h-3"
                percent="w"
                spark={false}
                value={userInfo.energy}
                maxvalue={1000}
                customStyle={{ width: `${Round(userInfo.energy / 10, 1)}%` }}
            />
          </div>
          <MainNav
            skillPoints={userInfo.skillPoints}
            userExp={userInfo.totalExp}
            isBranch={true}
            isTrainMode={isTrainMode}
            setIsNeedShown={setIsNeedShown}
            setTrainMode={setTrainMode}
          />
        </div>
      </Wrapper>
  );
};

export default Stage2;
