import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import IconLeft from "../../assets/icon-left.svg";
import Branch from "../../assets/icon-branch.svg";
import { FRENS_ARRAY } from "../../constants";
import IconBranch from "../../assets/icon-branch.svg";
import WebApp from "@twa-dev/sdk";
import {useNavigateByExp} from "../../utils/levels";
import {UserStore} from "../../store";
import MainNav from "../../components/MainNav";
import MainButton from "../../components/MainButton";
import {useEffect, useState} from "react";
import {REACT_APP_BACKEND_URL} from "../../config";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

interface Referer {
  tg_id: number;
  username: string;
  coins: number;
  [key: string]: any;
}
interface FrenList {
  referer: Referer;
  frens: Referer[];
  [key: string]: any;
}

export const Frens = () => {
  const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();
  const {userInfo, setUser} = UserStore((state) => ({userInfo: state.user, setUser: state.setUser}));
  const [isCopied, setIsCopied] = useState(false);
  const [frenList, setFrenlist] = useState<FrenList | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shownRewards, setShownRewards] = useState(false);

    WebApp.BackButton.onClick(()=>{
      WebApp.BackButton.hide();
      navigateByExp(userInfo.totalExp, null);
  });
  const handleClick = () =>{
    console.log('click');
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${REACT_APP_BACKEND_URL}/frens/`,
          headers: {Authorization: `Bearer ${userInfo.token}`}
        });
        setFrenlist(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(()=>{
    if(isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 500);
      return () => clearTimeout(timer);
    }}, [isCopied]);
  const handleCopy = () =>{
    navigator.clipboard.writeText(`https://t.me/notwise_bot?start=${userInfo.tg_id}`);
    setIsCopied(true);
  }
  const handleRewards = () =>{
    setShownRewards(!shownRewards);
  }
  return (
      <div className="block w-full h-dvh">
    <Wrapper className="bg-gradient-to-t from-[#e8b20934] via-[#00000036] to-[#000]  backdrop-blur-xl z-2 overflow-y-auto scrollable no-scrollbar">
      <div className="w-full">
        <div className="flex flex-col justify-center items-center p-3">
          <div className="flex flex-col justify-center items-center mt-3 mb-2 gap-1">
              <img src={'https://notwise.b-cdn.net/images/frenscheme3.svg'} className="w-9/12"/>
          </div>
          <h6 className="text-2xl w-full font-bold text-white text-center">Fren Zone</h6>
          <button onClick={handleRewards} className="mt-2 px-3 py-2 text-white font-medium border rounded-xl border-white/[.1]">
            {!shownRewards ? 'Rewards' : 'Hide'}
          </button>
          {shownRewards &&
          <div className="w-full mt-4 border border-white/[.06] rounded-xl">
            <div className="grid grid-cols-12 gap-y-1 items-end text-white/[60%]">
              <div className="col-span-3 border-b border-white/[.1] py-1">
              </div>
              <div className="col-span-3 text-base border-b border-white/[.1] py-1">
                FREN
              </div>
              <div className="col-span-3 text-sm border-b border-white/[.1] py-1">
                FREN'S FREN
              </div>
              <div className="col-span-3 text-xs border-b border-white/[.1] py-1">
                FREN'S FREN'S FREN
              </div>
            </div>
            <div className="grid grid-cols-12 items-center text-white/[60%] text-center">
              <div className="col-span-3 text-right border-r border-white/[.1] py-2 mr-2 pr-2">
                INVITE
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;100
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;10
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;5
              </div>
            </div>
            <div className="grid grid-cols-12 items-center text-white text-center border-y border-white/[.06] font-medium">
              <div className="col-span-3 text-right border-r border-white/[.1] py-2 mr-2 pr-2">
                LVL 1
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;1000
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;100
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;50
              </div>
            </div>
            <div className="grid grid-cols-12 items-center text-amber-400 text-center font-semibold">
              <div className="col-span-3 text-right border-r border-white/[.1] py-2 mr-2 pr-2">
                LVL 21
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;10000
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;1000
              </div>
              <div className="col-span-3 flex items-center py-2">
                <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;500
              </div>
            </div>
          </div>
          }
        </div>
        <div className="w-full bg-white/[.1] rounded-xl p-3 my-4">
          <div className="flex flex-col">
          <span className="text-white font-bold">Your invite link:</span>
          <span className="text-white/[60%] font-normal my-2 text-xs">According theory of 5 handshakes the user you invited may invite some influencer</span>
          </div>
          <div className="flex items-center justify-between w-full border border-white/[.2] rounded-lg text-white text-sm px-3 py-2">
            <span className="line-clamp-1">
              {!isCopied ?
                  `https://t.me/notwise_bot?start=${userInfo.tg_id}`
                  :
                  'Copied!'
              }
            </span>
            <img onClick={handleCopy} src={'https://notwise.b-cdn.net/icons/icon-copy.svg'} className={"w-6 min-w-6"} alt={'icon'}/>
          </div>
          <div className="w-full mt-2">
            <MainButton isLoading={false} btnText={"Invite your Fren!"} onClick={handleClick}/>
          </div>
        </div>
      </div>

      <h5 className="text-white font-bold text-lg mb-2">Your frens</h5>
      <div className="flex flex-col w-full mx-2 mb-20 border border-white/[.1] rounded-xl">
        <div className="grid grid-cols-12 items-center text-white border-b border-white/[.06] p-2">
          <div className="col-span-4 text-sm">
            Username
          </div>
          <div className="col-span-4 text-sm">
            Earned
          </div>
          <div className="col-span-4 text-sm">
            Branch
          </div>
        </div>
        { frenList && frenList?.referer &&
        <div className="grid grid-cols-12 p-2 items-center text-white">
          <div className="username col-span-4 text-sm">
            {frenList?.referer.username ? frenList?.referer.username : frenList?.referer.tg_id}
          </div>
          <div className="username col-span-4 text-sm">
            Invited you
          </div>
          <div className="col-span-4">
          <button className="float-right flex items-center px-4 py-2 bg-white/[.1] text-white text-sm rounded-xl">
            <img src={IconBranch} className="min-w-5 w-5 mr-1"/>
            Branch
          </button>
          </div>
        </div>
        }
        { frenList && frenList?.frens &&
            frenList?.frens.map((item, key) => (
                <div key={key} className="grid grid-cols-12 items-center text-white px-2 pb-2">
                  <div className="username col-span-4 text-sm">
                    {item.username ? item.username : item.tg_id}
                  </div>
                  <div className="username col-span-4 text-sm flex items-center">
                    <img src={'https://notwise.b-cdn.net/icons/coin-icon.png'} width={18} height={18} alt={'icon'}/>&nbsp;{item.coins}
                  </div>
                  <div className="col-span-4">
                    <Link to={`/freninfo/${item.tg_id}`} className="float-right flex items-center px-4 py-2 bg-white/[.1] text-white text-sm rounded-xl">
                      <img src={IconBranch} className="min-w-5 w-5 mr-1"/>
                      Branch
                    </Link>
                  </div>
                </div>
            ))
        }
      </div>
    </Wrapper>
          <MainNav skillPoints={userInfo.skillPoints} userExp={userInfo.totalExp} isBranch={false} isTrainMode={false} setIsNeedShown={false} setTrainMode={false}/>
      </div>
  );
};

export default Frens;
