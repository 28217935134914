import {Object3D, Scene, Texture, Vector3} from "three";
import {BatchedRenderer, ConstantValue, ParticleEmitter, ParticleSystem, QuarksUtil} from "three.quarks";

export class ParticleSystemManager {
    _batchRenderer!: BatchedRenderer;
    _effects: { [key: string]: Object3D};
    _textures: { [key: string]: Texture};
    private _scene!: Scene;

    constructor(effects: { [key: string]: Object3D}, textures: { [key: string]: Texture}) {
        this._effects = effects;
        this._textures = textures;
    }

    updateHeatEffect(temperature: number) {
        this._effects['EggHeat'].traverse((obj: Object3D) => {
            if (obj instanceof ParticleEmitter) {
                if (obj.name === 'smoke') {
                    if (temperature < 5) {
                        (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(0);
                    } else {
                        (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(temperature);
                    }
                } else {
                    if (temperature < 5) {
                        (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(0);
                    } else {
                        (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(30 + temperature);
                    }
                }
            }
        });
    }

    playClickEffect() {
        const effect = this._effects['Spark'].clone(true);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }


    playEggCracking() {
        const effect = this._effects['EggCracking'].clone(true);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }

    init(scene: Scene, gameStage: string, trainMode: boolean) {
        this._batchRenderer = new BatchedRenderer();
        this._scene = scene;
        scene.add(this._batchRenderer);

        if (gameStage === "stage1") {

            QuarksUtil.setAutoDestroy(this._effects['Spark'], true);
            QuarksUtil.stop(this._effects['Spark']);
            QuarksUtil.setAutoDestroy(this._effects['EggCracking'], true);
            QuarksUtil.stop(this._effects['EggCracking']);

            this._effects['EggHeat'].position.set(0, 1.5, 0);
            this._effects['EggHeat'].traverse((obj: Object3D) => {
                if (obj instanceof ParticleEmitter) {
                    this._batchRenderer.addSystem(obj.system);
                    (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(0);
                }
            });
            scene.add(this._effects['EggHeat']);
        } else if (gameStage === "stage2") {
            /*QuarksUtil.addToBatchRenderer(this._effects['Boom'], this._batchRenderer);
            QuarksUtil.stop(this._effects['Boom']);
            scene.add(this._effects['Boom']);*/
            QuarksUtil.setAutoDestroy(this._effects['LevelUp'], true);
            QuarksUtil.stop(this._effects['LevelUp']);
            QuarksUtil.setAutoDestroy(this._effects['ClawAttack'], true);
            QuarksUtil.stop(this._effects['ClawAttack']);
            QuarksUtil.setAutoDestroy(this._effects['Boom'], true);
            this._effects['Boom'].position.set(0,0, 2);
            QuarksUtil.stop(this._effects['Boom']);
            QuarksUtil.setAutoDestroy(this._effects['Bang'], true);
            this._effects['Bang'].position.set(0,0, 2);
            QuarksUtil.stop(this._effects['Bang']);
            QuarksUtil.setAutoDestroy(this._effects['HealOnce'], true);
            QuarksUtil.stop(this._effects['HealOnce']);
            QuarksUtil.setAutoDestroy(this._effects['HeartPoof'], true);
            QuarksUtil.stop(this._effects['HeartPoof']);
            QuarksUtil.setAutoDestroy(this._effects['QuestionsMark'], true);
            QuarksUtil.stop(this._effects['QuestionsMark']);
            QuarksUtil.setAutoDestroy(this._effects['Feed'], true);
            QuarksUtil.stop(this._effects['Feed']);
            /*scene.add(this._effects['Bang']);
            scene.add(this._effects['HealOnce']);
            scene.add(this._effects['HeartPoof']);
            scene.add(this._effects['QuestionsMark']);
            scene.add(this._effects['Feed']);*/
        }
    }

    update(delta: number) {
        this._batchRenderer.update(delta);
    }

    playQuestionMarks() {
        const effect = this._effects['QuestionsMark'].clone(true);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }

    playHealEffect() {
        const effect = this._effects['HealOnce'].clone(true);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }

    static IndexToFoodName = ["Food2", "Food4", "Food1", "Food3"];

    playFeedEffect(index: number) {
        const effect = this._effects['Feed'].clone(true);
        ((effect.children[0] as ParticleEmitter).system as ParticleSystem).texture = this._textures[ParticleSystemManager.IndexToFoodName[index]];
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }

    playLevelUpEffect() {
        const effect = this._effects['LevelUp'].clone(true);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }

    playEggBreakEffect() {
        const effect = this._effects['EggBreak'].clone(true);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }

    playPet() {
        const effect = this._effects['HeartPoof'].clone(true);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }

    playClawScratch(rotate: number) {
        const effect = this._effects['ClawAttack'].clone(true);
        this._scene.add(effect);
        (((effect.children[0] as ParticleEmitter).system as ParticleSystem).startRotation as ConstantValue).value = rotate;
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }

    playAttackBoom(point: Vector3) {
        const effect = this._effects['Boom'].clone(true);
        effect.position.add(point);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
        }

    playAttackBang(point: Vector3) {
        const effect = this._effects['Bang'].clone(true);
        effect.position.add(point);
        this._scene.add(effect);
        QuarksUtil.addToBatchRenderer(effect, this._batchRenderer);
        QuarksUtil.restart(effect);
    }
}