import * as THREE from "three";
import AssetsManager from "./AssetsManager";
import {
  DUMMY_ANIMATION_NAMES,
  EGG_ANIMATION_NAMES,
  OWL_ANIMATION_NAMES,
  TREE_ANIMATION_NAMES,
} from "../../constants";

export class AnimationManager {
  _actions: any;
  _models: any;
  _clock: any;
  _tree: any;
  _dummy: any;
  _mixers: any;

  _activeAction: any;
  _lastAction: any;

  _gameStage: any;
  _assetsManager: any;
  _trainMode: boolean;

  constructor(
    assetsManager: AssetsManager,
    gameStage: string,
    trainMode: boolean
  ) {
    this._actions = {
      tree: {},
      character: {},
      dummy: {},
    };

    this._gameStage = gameStage;

    this._assetsManager = assetsManager;

    this._clock = new THREE.Clock();

    this._activeAction = {};

    this._lastAction = {};

    this._trainMode = trainMode;

    this._models = {};

    this._mixers = {};

    this.setModel();
    this.initActions();
    this.initAnimations();
    this.animation();
    this.initEvents();
  }

  // stageUp(upStage: string) {
  //   this._gameStage = upStage;
  //   this.setModel();
  //   this.initActions();
  //   this.playAction("idle");
  // }

  setModel() {
    this._models = this._assetsManager._models;
    this._mixers.character = new THREE.AnimationMixer(this._models.character);
    this._mixers.tree = new THREE.AnimationMixer(this._models.tree);

    if (this._gameStage === "stage2") {
      this._mixers.dummy = new THREE.AnimationMixer(this._models.dummy);
    }
  }

  initActions() {
    TREE_ANIMATION_NAMES.forEach((name: string) => {
      this._actions.tree[name] = this._mixers.tree.clipAction(
        this._assetsManager._animationClips.tree[name]
      );
      // this._actions.tree[name].loop = THREE.LoopOnce;
      // this._actions[name].enable = true;
    });

    switch (this._gameStage) {
      case "stage1":
        EGG_ANIMATION_NAMES.forEach((name: string) => {
          this._actions.character[name] = this._mixers.character.clipAction(
            this._assetsManager._animationClips.character[name]
          );
          this._actions.character[name].loop = THREE.LoopOnce;
          // this._actions[name].enable = true;
        });
        break;
      case "stage2":
        OWL_ANIMATION_NAMES.forEach((name: string) => {
          this._actions.character[name] = this._mixers.character.clipAction(
            this._assetsManager._animationClips.character[name]
          );
          this._actions.character[name].clampWhenFinished = true;
          if (name !== "idle") {
            this._actions.character[name].loop = THREE.LoopOnce;
          }
        });

        DUMMY_ANIMATION_NAMES.forEach((name: string) => {
          this._actions.dummy[name] = this._mixers.dummy.clipAction(
            this._assetsManager._animationClips.dummy[name]
          );
          this._actions.dummy[name].loop = THREE.LoopOnce;
        });
        break;
      case "stage3":
        OWL_ANIMATION_NAMES.forEach((name: string) => {
          this._actions.character[name] = this._mixers.character.clipAction(
            this._assetsManager._animationClips.character[name]
          );
          // this._actions.character[name].loop = THREE.LoopOnce;
        });
        break;
      default:
        break;
    }
  }

  initAnimations() {
    this.playAction("tree", "idle_tree");

    // this._gameStage !== "stage1" && this.playAction("character", "idle");
    // this._gameStage === "stage2" && this.playAction("dummy", "damage_v1");
  }

  playAction(type: string, name: string) {
    const action = this.getAction(type, name);
    if (!action) return;
    this.setAction(type, action);
  }

  resetPlayAction(type: string, name: any) {
    const action = this.getAction(type, name);
    if (!action) return;
    action.reset().play();
  }

  getAction(type: string, name: string) {
    if (!this._actions[type][name]) {
      console.error("no such animation of ", type, name);
      return null;
    }
    return this._actions[type][name];
  }

  setAction(type: string, toAction: any) {
    this._lastAction[type] = this._activeAction[type];
    this._activeAction[type] = toAction;

    if (this._lastAction[type] != this._activeAction[type]) {
      this._lastAction[type]?.fadeOut(1);
      this._activeAction[type]
        .reset()
        .setEffectiveTimeScale(1)
        .setEffectiveWeight(1)
        .fadeIn(1)
        .play();
    } else {
      this._activeAction[type].reset().play();
    }
  }

  initEvents() {
    this._mixers.character.addEventListener("finished", (ev: any) => {
      if (this._gameStage !== "stage1" && !this._trainMode)
        OWL_ANIMATION_NAMES.forEach((name: string) => {
          if (ev.action === this._actions.character[name]) {
            console.log(name, " animation finished", ev);
          }
        });
      if(this._gameStage !== "stage1"){
        this.playAction("character", "idle");
      }
    });
  }

  animation() {
    requestAnimationFrame(this.animation.bind(this));
    const deltaDate = this._clock.getDelta();

    this._mixers.character.update(deltaDate);

    this._mixers.tree.update(deltaDate);

    this._gameStage === "stage2" && this._mixers.dummy.update(deltaDate);
  }

  dispose() {
    //dispose character animation

    this._mixers.character.stopAllAction();
    switch (this._gameStage) {
      case "stage1":
        EGG_ANIMATION_NAMES.forEach((name: string) => {
          this._mixers.character.uncacheClip(
            this._assetsManager._animationClips.character[name]
          );
        });
        break;
      case "stage2":
      case "stage3":
        OWL_ANIMATION_NAMES.forEach((name: string) => {
          this._mixers.character.uncacheClip(
            this._assetsManager._animationClips.character[name]
          );
        });
        break;

      default:
        break;
    }
    this._mixers.character.uncacheRoot(this._models.character);

    // dispose tree aniamtion
    this._mixers.tree.stopAllAction();
    TREE_ANIMATION_NAMES.forEach((name: string) => {
      this._mixers.tree.uncacheClip(
        this._assetsManager._animationClips.tree[name]
      );
    });
    this._mixers.tree.uncacheRoot(this._models.tree);

    // dispose dummy animation
    if (this._gameStage === "stage2") {
      this._mixers.dummy.stopAllAction();
      DUMMY_ANIMATION_NAMES.forEach((name: string) => {
        this._mixers.dummy.uncacheClip(
          this._assetsManager._animationClips.dummy[name]
        );
      });
      this._mixers.dummy.uncacheRoot(this._models.dummy);
    }
  }
}

export default AnimationManager;
