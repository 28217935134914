// import { useCallback, useEffect, useRef, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import { Toaster } from "react-hot-toast";

import Home from "./pages/Home";
import Stage1 from "./pages/Stage/Stage1";
import Stage2 from "./pages/Stage/Stage2";
import Stage3 from "./pages/Stage/Stage3";
import Editor from "./pages/Editor";
import Squad from "./pages/Squad";
import LeaderBoard from "./pages/LeaderBoard";
import LeaderBird from "./pages/LeaderBird";
import Tree from "./pages/Tree";
import Fight from "./pages/Fight";
import Shop from "./pages/Store";
import Store from "./pages/StoreItemDetails";
import Frens from "./pages/Frens";
import Earn from "./pages/Earn";
import Profile from "./pages/Profile";
import ItemView from "./pages/ItemView";
import Train from "./pages/Train";

import { ScreenOrientation } from "./components/ScreenOrientation";
import { LoadingProvider } from "./components/LoadingProvider";
import { TonConnectProvider } from "./components/TonConnectProvider";
import { DayTime } from "./components/GameScene/dayTime/DayTime";
import AddSquad from "./pages/AddSquad";
import SquadList from "./pages/SquadList";
import InventoryItemDetails from "./pages/InventoryItemDetails";
import FrenInfo from "./pages/FrenInfo";
import { DebugView } from "./components/DebugView/DebugView";
import { useEnergyUpdate} from "./hooks/useEnergy";
import { useTemperatureUpdate } from "./hooks/useTemperature";

const Wrapper = styled.div`
  position: absolute;
  background: transparent;
  width: 100dvw;
  height: 100dvh;
  z-index: 1;
  pointer-events: none;
  touch-action: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

function App() {
  useEnergyUpdate()
  useTemperatureUpdate()

  return (
    <TonConnectUIProvider
      manifestUrl="https://node1.irys.xyz/6jjdY6x_PUMEouHDDquPBalqKNU__bpecDp-erCKy5k"
      uiPreferences={{ theme: THEME.DARK }}
      walletsListConfiguration={{
        includeWallets: [
          {
            appName: "safepalwallet",
            name: "SafePal",
            imageUrl:
              "https://s.pvcliping.com/web/public_image/SafePal_x288.png",
            aboutUrl: "https://www.safepal.com/download",
            jsBridgeKey: "safepalwallet",
            platforms: ["ios", "android", "chrome", "firefox"],
          },
          {
            appName: "tonwallet",
            name: "TON Wallet",
            imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
            aboutUrl:
              "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
            universalLink: "https://wallet.ton.org/ton-connect",
            jsBridgeKey: "tonwallet",
            bridgeUrl: "https://bridge.tonapi.io/bridge",
            platforms: ["chrome", "android"],
          },
        ],
      }}
    >
      <div className="App w-screen h-screen select-none">
          <>
            <TonConnectProvider>
              <ScreenOrientation>
                <Editor />
                <Wrapper>
                  <LoadingProvider>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/stage1" element={<Stage1 />} />
                        <Route path="/stage2" element={<Stage2 />}>
                          <Route path="train" element={<Train />} />
                        </Route>
                        <Route path="/stage3" element={<Stage3 />} />
                        <Route path="/squad" element={<Squad />} />
                        <Route path="/add-squad" element={<AddSquad />} />
                        <Route path="/squad-list" element={<SquadList />} />
                        <Route path="/squad/:id" element={<LeaderBoard />} />
                        <Route path="/leaderbird" element={<LeaderBird />} />
                        <Route path="/fight" element={<Fight />} />
                        <Route path="/fight-tree" element={<Tree />} />
                        <Route path="/store" element={<Shop />} />
                        <Route path="/store/:slug/:id" element={<Store />} />
                        <Route path="/frens" element={<Frens />} />
                        <Route path="/earn" element={<Earn />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/inventory/details/:id" element={<InventoryItemDetails />} />
                        <Route path="/freninfo/:id" element={<FrenInfo />} />
                        <Route
                          path="/lootbox/:id/itemview"
                          element={<ItemView />}
                        />
                        <Route path="*" element={<Navigate to="/" />} />
                      </Routes>
                  </LoadingProvider>
                </Wrapper>
              </ScreenOrientation>
            </TonConnectProvider>
            <Toaster />
          </>
      </div>
    </TonConnectUIProvider>
  );
}

export default App;
