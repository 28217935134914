import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { BatchedMesh, DoubleSide, FrontSide, Group, LoadingManager, Material, Mesh, MeshBasicMaterial, MeshLambertMaterial, MeshStandardMaterial, Object3D, SkinnedMesh } from "three";
import { RENDERER_PROPS } from "../../../constants/rendering";

const ALLOW_EXPENSIVE_MATERIALS: string[] = [
  'T_Roof',
];
const ALLOW_SHADOW_CASTING: string[] = [
  'T_Body',
  'T_Tree',
  'T_Leaves'
];
const DEBUG_UNIQUE_MATERIALS = false;

type ExtendedGLTF = GLTF & {
  meshes: Mesh[];
  materials: Record<string, Material>;
};

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath(
  "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
);

// NOTE Apply BatchedMesh to non-skinned objects
const applyBatchedMeshes = (objectMaterialMap: Record<string, Mesh[]>, scene: Group) => {
  Object.entries(objectMaterialMap).forEach(([ materialId, meshes ]) => {
    const hasSkinnedMeshes = meshes.findIndex(item => item instanceof SkinnedMesh) !== -1;

    if (hasSkinnedMeshes) {
      return;
    } else {
      meshes.forEach(mesh => mesh.visible = false);
    }

    const material = meshes[0].material as Material;

    material.side = DoubleSide;

    const batchedMesh = new BatchedMesh(
      meshes.length,
      5000,
      5000,
      material
    );

    try {
      for (const mesh of meshes) {
        const instanceId = batchedMesh.addGeometry(mesh.geometry);
        batchedMesh.setMatrixAt(instanceId, mesh.matrixWorld);
      }

      scene.add(batchedMesh);
    } catch {}
  });
};

export const GLTFLoaderPromise = async (
  src: any,
  percentageCallback: Function,
  startPercent: number,
  endPercent: number,
): Promise<ExtendedGLTF> => {
  const loadingManager = new LoadingManager();
  loadingManager.onProgress = (
    url: string,
    loaded: number,
    total: number
  ) => {
    percentageCallback(
      Math.ceil(startPercent + (loaded / total) * (endPercent - startPercent))
    );
  };
  const loader = new GLTFLoader(loadingManager)
    .setDRACOLoader(dracoLoader);

  const objectMaterialMap: Record<string, Mesh[]> = {};
  const materials: Record<string, Material> = {};
  const meshes: Mesh[] = [];

  const gltf: GLTF = await loader.loadAsync(src);

  gltf.scene.traverse((child: Object3D) => {
    const mesh = child as Mesh;

    if (mesh.isMesh) {
      meshes.push(mesh);
    }

    if (mesh.material) {
      // NOTE Three-imported GLTFs do not support multi-material meshes, safely assume it's never an array
      const material = mesh.material as Material;

      if (!materials[material.name]) {
        materials[material.name] = material;
      } else {
        mesh.material = materials[material.name];
      }

      if (!objectMaterialMap[material.name]) {
        objectMaterialMap[material.name] = [];
      }

      objectMaterialMap[material.name].push(mesh);
    }
  });

  Object.entries(materials).forEach(([ materialId, material ]) => {
    const originalMaterial = material as MeshStandardMaterial;
    let updatedMaterial: Material;

    if (originalMaterial.map) {
      originalMaterial.map.anisotropy = 1;
      originalMaterial.needsUpdate = true;
    }

    if (ALLOW_EXPENSIVE_MATERIALS.includes(materialId)) {
      updatedMaterial = new MeshStandardMaterial({
        name: materialId,
        color: originalMaterial.color,
        map: originalMaterial.map,
        side: FrontSide,
        roughness: originalMaterial.roughness,
        roughnessMap: originalMaterial.roughnessMap,
        metalness: originalMaterial.metalness,
        metalnessMap: originalMaterial.metalnessMap,
      });
    } else {
      // NOTE Near-equivalent approximate of MeshStandardMaterial at a fraction of the cost
      updatedMaterial = new MeshLambertMaterial({
        name: materialId,
        color: originalMaterial.color,
        map: originalMaterial.map,
        side: FrontSide,
        emissive: 0xffff88,
        emissiveIntensity: 0.015
      });
    }

    if (DEBUG_UNIQUE_MATERIALS) {
      updatedMaterial = new MeshBasicMaterial({
        name: materialId,
        color: Math.random() * 0x888888 + 0x888888
      });
    }

    materials[materialId] = updatedMaterial;

    objectMaterialMap[materialId].forEach(mesh => {
      mesh.material = updatedMaterial;
    });

    originalMaterial.dispose();
  });

 // applyBatchedMeshes(objectMaterialMap, gltf.scene);

  gltf.scene.traverse(child => {
    const mesh = child as Mesh;

    if (!mesh.isMesh) {
      return;
    }

    mesh.matrixAutoUpdate = false;
    mesh.matrixWorldAutoUpdate = false;

    if (RENDERER_PROPS.shadowMapEnable) {
      mesh.castShadow = ALLOW_SHADOW_CASTING.includes((mesh.material as Material).name);
      mesh.receiveShadow = true;
    }
  });

  // FIXME This should have not been done
  //       but logic is already bound to it, so leaving it as-is
  //       Custom data should be appended to .userData
  const extendedGLTF = gltf as ExtendedGLTF;
  extendedGLTF.materials = materials;
  extendedGLTF.meshes = meshes;

  return extendedGLTF;
};
