import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 48px 32px;
  width: 100%;
  height: 100%;
`;

export const Train = () => {
  return (
    <Wrapper>
      <p className="text-2xl font-bold text-white">Train mode</p>
    </Wrapper>
  );
};

export default Train;
