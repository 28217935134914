import AssetsManager from "./AssetsManager";
import { SceneRenderer } from "./rendering/SceneRenderer";
import AnimationManager from "./AnimationManager";
import UserActionManager from "./UserActionManager";
import { TraitsInfo } from "../../types/interface";

export interface GameOptions {
  canvas: HTMLDivElement;
  assetsManager: AssetsManager;
  gameActionManagerCallback: any;
  gameStage: string;
}

export class Game {
  _canvasDiv: HTMLDivElement;
  _assetsManager: AssetsManager;
  _animationManager: AnimationManager;
  _sceneRenderer: SceneRenderer;
  _userActionManager: any;
  _gameStage: string;
  _gameActionManagerCallback: any;
  _trainMode: boolean;

  constructor(options: GameOptions) {
    this._canvasDiv = options.canvas;

    this._assetsManager = options.assetsManager;

    this._gameStage = options.gameStage;

    this._gameActionManagerCallback = options.gameActionManagerCallback;

    this._trainMode = false;

    this._animationManager = new AnimationManager(
      this._assetsManager,
      this._gameStage,
      this._trainMode
    );

    this._sceneRenderer = new SceneRenderer(
      this._assetsManager,
      this._gameActionManagerCallback,
      this._gameStage,
      this._trainMode
    );

    this._userActionManager = new UserActionManager(
      options,
      this._animationManager,
      this._sceneRenderer._particleSystemManager,
      this._sceneRenderer._camera,
      this._sceneRenderer._scene,
      this._sceneRenderer._light
    );

    this.initialize();
  }

  initialize() {
    this._canvasDiv.appendChild(this._sceneRenderer._renderer.domElement);
    this.animate();
  }

  stageUp = async (upStage: string) => {
    if (upStage === this._gameStage) return;

    this._gameStage = upStage;

    this._sceneRenderer.removeCharacter();

    await this._assetsManager.upgradeModel(this._gameStage);

    this._animationManager.dispose();
    this._animationManager = new AnimationManager(
      this._assetsManager,
      this._gameStage,
      this._trainMode
    );

    this._sceneRenderer.stageUp(this._gameStage);

    this._userActionManager.stageUp(this._gameStage);
  };

  modelUpdateChecker(trait: TraitsInfo, stringLight: string | undefined) {
    this._assetsManager.modelUpdateChecker(trait, stringLight);
  }

  switchTrainMode(trainMode: boolean) {
    if (this._gameStage !== "stage2") return;
    this._trainMode = trainMode;
    console.log(`switched to ${this._trainMode ? "train" : "branch"} mode`);
    this._sceneRenderer.switchTrainMode(trainMode);
    this._userActionManager._trainMode = this._trainMode;
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));
    this._sceneRenderer.render();
  }

  dispose() {}
}
