import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";

export const ScreenOrientation = ({ children }) => {
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );

  const handleResize = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  const handleOrientationChange = (ev) => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    const lockOrientation = async () => {
      try {
        if (window.screen.orientation && window.screen.orientation.lock) {
          await window.screen.orientation.lock("portrait");
        }
      } catch (error) {
        console.error("Orientation lock failed:", error);
      }
    };

    lockOrientation();
  }, []);

  return (
    <div className="w-[100dvw] h-[100dvh] overflow-hidden">
      {!isPortrait && (
        <div className="absolute w-full h-full bg-black z-10 flex flex-col justify-center items-center text-white text-center">
          <img src="https://notwise.b-cdn.net/icons/rotate.svg" width="90"/>
          <span className="mt-10 font-medium">
            <b className="text-xl">NOT Wise is 📱 portrait only</b>
            <br />
            Please turn your device ;)
          </span>
        </div>
      )}
      {children}
      {/* {isPortrait && children} */}
    </div>
  );
};
